.vmznds-error-boundary {
	&__error {
		margin: $containerMarginVertically $containerMarginHorizontally;
		padding: $containerMarginHorizontallyInner $containerMarginHorizontallyInner;
		border: 2px solid $vmzRed;
		background: rgba(255,255,255,.9);
		p {
			font-size: 12px;
		}

		.ms3-map-overlay & {
			margin: 60px;
		}
	}
}
