.vmznds-content-box__content .ms3-feature-details-content {

	.vmznds-teaser__list {
		hr {
			margin: 1em;
		}
	}

	.vmznds-teaser {
		padding-top: 1.5em;
		padding-bottom: 1.5em;

		&__image {
			img {
				width: 100%;
				height: auto;
			}
		}

		&--with-image &__image {
			height: 23.93162666666667%;
		}

		&__header {
			margin: 2px 0;
			margin-top: -6px;

			h1, h2, h3, h4, h5 {
				margin: 0;
			}
		}

		&__text {
			p {
				margin: 0.5em 0;
			}
		}

		// TODO wo ist dieser link sichtbar? eventuell angleichen an mixin vmznds-link
		&__link {
			display: block;
			padding: 10px;
			margin: -10px;

			color: inherit;
			text-decoration: none;

			&:focus {
				background: darkgrey;
			}
		}
	}
}
