.vmznds-main-panel-container {
	$b: &;

	&__content {
		display: flex;
		position: relative;
		flex-direction: column;
		flex-grow: 1;
		flex-shrink: 1;
		overflow: auto;
	}

	&__main-list-wrapper {
		.ms3-panel {
			background-color: $backgroundColorA !important;
		}

		.ms3-list {
			padding: 0;
		}
	}
}
