.ms3-modal { // Container von .ms3-attribution of mobile
	&__inner {
		//min-width: 50vw;
		border-width: 1px;
		border-radius: $containerBorderRadius;
		border-color: $containerBorderColor;
		border-style: solid;
		padding: $containerMarginVerticallyInner $containerMarginHorizontallyInner;

		& .ms3-dialog-close-button {
			&:before,
			&:after {
				background-color: $containerBorderColor;
			}
			&:after {
				border-bottom-left-radius: 2px;
			}
		}
	}

	.ms3-attribution {
		padding: 0 $containerMarginHorizontally;
		line-height: 29px;
	}
}
