.vmznds-map-overlay-bottom {
	position: absolute;
	left: 70px;
	right: 70px;
	bottom: 11px;
	text-align: center;
    z-index: 1;

	.ms3-wrapper--fullscreen & {
		left: 110px; // see _mapsight-ui-integration .ms-logo width
		right: 70px;
		bottom: $containerMarginVertically;
	}

	* {
		max-width: unset;
		font-size: 12px; // Chris: 10px, das rendert der Browser aber zu unleserlich
		font-weight: $regular-font-weight;
	}

	// workaround to not override "display: none" for mobile
	.ms3-wrapper--fullscreen .ms3-info-overlay__area {
		display: inline-block;
	}
}
