@import "../mixins/list-group-name";

.vmznds-feature-details-pundr {
	$b: &;

	// reset left border to zero, needed for lines below headings
	margin-left: -$containerMarginHorizontallyInner;
	margin-right: -$containerMarginHorizontallyInner;

	&__header,
	&__description,
	&__area-headline,
	&__area-content,
	&__stops-headline,
	&__stops-content,
	&__statistics-content,
	&__statistics-headline {
		padding-left: $containerMarginHorizontallyInner;
		padding-right: $containerMarginHorizontallyInner;
	}

	&__header {
		margin: {
			top: 5px;
			bottom: 5px;
		}
	}

	&__headline-icons {
		display: flex;
	}

	&__headline {
		flex-grow: 1;
		flex-shrink: 1;
		font-weight: $bold-font-weight;
		font-size: 20px;
		letter-spacing: 0;
		line-height: 22px;
		margin: 0;
		margin-top: 6px;
		padding: 0;
		max-width: calc(100% - 52px);
		hyphens: auto;
		overflow-wrap: break-word;
		word-break: break-word;
	}

	&__icons {
		position: absolute;
		top: 0;
		right: 0;
		flex-grow: 0;
		flex-shrink: 0;
		display: inline-flex;
		padding: 7px 0px 7px 8px;
	}

	&__icon {
		display: inline;
		flex-grow: 0;
		flex-shrink: 0;
		align-self: start;

		&--pundr {
		}

		&--occupation {
			margin-top: 10px;
			margin-left: -4px;
		}
	}

	&__total-capacity {
		font-weight: $bold-font-weight;
	}

	&__address {
		font-weight: $medium-font-weight;
		margin: {
			top: 5px;
		}
	}


	&__image {
		display: block;
		width: calc(100% + 22px);
		margin: {
			left: -11px;
			right: -11px;
			top: 5px;
			bottom: 5px;
		}
	}

	h3 {
		font-size: 14px;
		font-weight: $medium-font-weight;
		margin-bottom: 0;
	}

	&__area {
	}

	&__area-headline {
		// Herbert: anders als Design von Chris, muss zu Parkhäuser H City passen
		@include vmznds-list-group-name;
	}

	&__area-content {
		font-weight: $medium-font-weight;

		#{$b}__area-headline + & {
			padding-top: $containerMarginVerticallyInner;
		}
	}

	&__area-slots {
		margin: 0;
	}

	&__area-slot {
		//font-weight: $medium-font-weight;
		//font-size: 14px;
		//letter-spacing: 0;
		margin: {
			top: 5px;
			bottom: 10px;
		}

		&--available {
		}

		&--full {
		}

		&--unknown {
		}
	}

	&__area-slot-key {
	}

	&__area-slot-value {
		//font-weight: $medium-font-weight;
		//font-size: 14px;
		//letter-spacing: 0;
		margin: 0;

		#{$b}__area-slot--available & {
			color: #4D8D08;
		}

		#{$b}__area-slot--full & {
			color: #BE1E1E;
		}

		#{$b}__area-slot--unknown & {
			display: none;
		}
	}

	&__statistics {
		&-headline {
			// Herbert: anders als Design von Chris, muss zu Parkhäuser H City passen
			@include vmznds-list-group-name;
		}

		&-content {
			p {
				line-height: 20px;
			}

			a {
				font-size: 14px;
				padding-left: $containerMarginHorizontallyInner;
				padding-right: $containerMarginHorizontallyInner;
				padding-top: 6px; // $containerMarginVerticallyInner / 2 ;
				padding-bottom: 6px; // $containerMarginVertically / 2;
				display: block;
			}

			select {
				margin: $containerMarginVerticallyInner 0 $containerMarginVerticallyInner .7em;
				color: $foregroundColorC;
				background-color: $linkBgHovered;
				font-size: 12px;
				line-height: 1;
				border: {
					style: solid;
					width: 1px;
					radius: 4px;
					color: $containerBorderColor;
				}
			}
		}
	}

	&__haltestelle {
		font-weight: $bold-font-weight;
	}

	&__stops {
	}

	&__stops-headline {
		@include vmznds-list-group-name;
	}

	&__stop {
		display: flex;
		flex-direction: row;

		margin: {
			top: 10px;
			bottom: 10px;
		}
	}

	&__stop-symbol {
		width: 59px;
		padding-right: 3px;
		flex-grow: 0;
		flex-shrink: 0;
		text-align: center;
	}

	&__stop-body {
		flex-grow: 1;
		flex-shrink: 1;
	}

	&__stop-events {
		display: inline;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__stop-event {
		display: inline;

		&:not(:last-child)::after {
			content: ", ";
		}
	}

	&__stop-more-lines {
		display: inline;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__stop-more-line {
		display: inline;
		margin: {
			left: 5px;
			right: 5px;
		}
	}

	&__disclaimer {
		font-size: 12px;
		color: $mapControlForegroundColorB;
		line-height: 1;
		padding: {
			left: 11px;
			right: 11px;
			top: 38px - 12px;
		};
		margin-bottom: 0;
	}
}
