.vmznds-footer-navigation {
	font-size: 13px;

	$b: &;

	.ms3-additional-container & {
		margin-top: $containerMarginVertically;
	}

	&--fixed {
		height: $footerHeight;
		left: 0;
		right: 0;
		bottom: 5px;
		position: fixed;
	}

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-end;
		list-style: none;
		margin: {
			left: $containerMarginHorizontally;
			right: $containerMarginHorizontally;
			top: 3px;
		}
		padding: 0 $containerMarginHorizontallyInner;

		#{$b}:not(#{$b}--fixed) & {
			margin: {
				top: $containerMarginVertically - 7px;
				bottom: $containerMarginVertically - 7px;
				left: 0;
				right: 0;
			}
		}
	}

	&__list-entry {
		color: black;
		line-height: 1;

		& + & {
			border-left: {
				style: solid;
				width: 1px;
				color: black;
			}
		}


		display: block;
		padding: {
			left: $containerMarginHorizontallyInner;
			right: $containerMarginHorizontallyInner;
			top: 1px;
			bottom: 1px;
		}
		margin: {
			top: 7px;
			bottom: 7px;
		};

		#{$b}:not(#{$b}--fixed) &:first-child  {
			padding-left: 0;
		}
		#{$b}:not(#{$b}--fixed) &:last-child  {
			padding-right: 0;
		}

	}

	&__link {
		text-decoration: none;
		color: inherit;

		&:hover,
		&:focus {
			outline: 0;
			text-decoration: underline;
		}
	}

	// hamdvermessene Grenzen
	@media screen and (min-width: 430px) and (max-width: 613px) {
		&__list-entry {
			&:nth-child(4) {
				padding-right: 0;
			}
			&:nth-child(5) {
				border-left: none;
				padding-left:  $containerMarginHorizontallyInner + $containerMarginHorizontallyInner + 1px; // add what was taken above, so column keeps wrapped
			}
		}
	}
	@media screen and (min-width: 337px) and (max-width: 429px) {
		&__list-entry {
			&:nth-child(3) {
				padding-right: 0;
			}
			&:nth-child(4) {
				border-left: none;
				padding-left:  $containerMarginHorizontallyInner + $containerMarginHorizontallyInner + 1px; // add what was taken above, so column keeps wrapped
			}
		}
	}
	@media screen and (max-width: 336px) {
		&__list-entry {
			&:nth-child(2), &:nth-child(4) {
				padding-right: 0;
			}

			&:nth-child(3), &:nth-child(5) {
				border-left: none;
				padding-left: $containerMarginHorizontallyInner + $containerMarginHorizontallyInner + 1px; // add what was taken above, so column keeps wrapped
			}
		}
	}
}
