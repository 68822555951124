.vmznds-content-box {
	// FIXME just a guessed value as placeholder. this is max-with + padding-right from
	// .ms3-feature-details-content
	$content-width: 730px;
	$b: &;

	display: block;

	pointer-events: all;
	height: 100%;
	position: relative;
	z-index: 10000;

	.ms3-wrapper--mobile & {
		margin-bottom: $containerMarginVertically;
	}

	&--align-with-panel-content {
		// TODO: update magic number
		// height of panel "header" in "fullscreen" state (logo + menu button)
		padding-top: 245px;
	}

	.ms3-wrapper--fullscreen &:not(&--full-width) {
		background-color: #fafafa;
		background-image: url("/assets/img/content-page-bg.svg?v=2021");
		background-repeat: no-repeat;
		background-position-x: $content-width - 600px;
		background-position-y: -300px;
		background-size: 1200px 1200px;
	}

	&--full-width {
		background-color: #7777; // transparenter bereich wo bei den Kameras die Karte durchscheint
	}

	&__content {
		display: block;
		position: relative; // needed by close button on wide camera view

		height: 100%;
		background-color: $backgroundColorA;
		padding: 20px;
		overflow-y: scroll;

		.ms3-wrapper--fullscreen & {
			border-right: {
				style: solid;
				width: 1px;
				color: $borderColorA;
			}
		}

		.ms3-wrapper--mobile & {
			border: {
				style: solid;
				width: 1px;
				color: $borderColorA;
				radius: 7px;
			}
		}

		.ms3-wrapper--fullscreen #{$b}:not(#{$b}--full-width) & {
			max-width: $content-width;
			padding: 74px 60px;
		}
	}
}
