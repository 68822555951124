.showup-enter {
	transition: transform 0.3s ease-out;
	transform: translateX(-20px);
}

.showup-enter.showup-enter-active {
	transform: translateX(0);
}

.showup-leave {
	transition: transform 0.3s ease-in;
	transform: translateX(0);
}

.showup-leave.showup-leave-active {
	transform: translateX(-20px);
}
