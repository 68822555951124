.js-focus-visible {
	:focus:not(.focus-visible) {
		outline: none;
		//outline: cyan;
		//background-color: hotpink;
	}

	.focus-visible:focus {
		outline: $focus-outline;
		// see also blocks/_panel-boxes for special focus handling of start page boxes
	}

	// nicht für .vmznds-panel-box__body,
	// make focus visible, outline is completely or partly invisible
	.ms3-wrapper--fullscreen .vmznds-logo__link,
	.ms3-wrapper--fullscreen .vmznds-content-box,
	.ms3-wrapper--mapOnly .vmznds-panel-box__box,
	main.vmznds-panel-boxes,
	.ms3-map-target,
	.ms3-list__item--preselected {
		position: relative;

		&.focus-visible {
			outline: none !important;

			&::after {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				border: $focus-outline;
				pointer-events: none;
			}
		}
	}
}
