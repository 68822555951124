@import "../variables";

.vmznds-list-two-wrapper {
	left: 0;
	background-color: $backgroundColorA !important;

	&__header {
		display: block !important;
	}
}
