@import "../variables";

.ms3-features-sorting {
	margin-top: 18px;

	&__panel {
		color: $foregroundColorC;
		background-color: $backgroundColorC;

		select {
			margin: $containerMarginVerticallyInner 0;
			color: $foregroundColorC;
			background-color: $linkBgHovered;
			font-size: 13px;
			line-height: 1;
			border: {
				style: solid;
				width: 1px;
				radius: 4px;
				color: $containerBorderColor;
			}
		}
	}
}

.vmznds-panel-option--suchen-sortieren {
	.ms3-features-sorting {
		margin: 0;
		padding: 0;
		border: none;
		position: static;

		.ms3-features-sorting__panel {
			margin: 0;
			padding: 0;
			box-shadow: none;
			border: none;
		}
	}
}
