// DO NOT REMOVE IN CLEANUPS
// see app-wrapper-start.jsx instead

.vmznds-button-scroll-to-map {
	.vmznds-container-left--fixed & {
		display: block;
	}

	z-index: 1;
	position: fixed;
	width: 40px;
	min-height: 40px;
	padding: 0;
	margin: 5px 7px;
	background: #fff;
	border: 0;
	border-radius: 3px;
	box-shadow: 0 0 12px rgba(0,0,0,.35);
	bottom: 0;
	right: 0;

	&:before {
		display: block;
		content: "";
		width: 100%;
		height: 40px;
		background-repeat: no-repeat;
		background-position: 50%;
		background-image: url(../img/mapsight-ui/map.svg?v=2021);
		margin-top: 0;
		margin-left: 0;
	}
}
