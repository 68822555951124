.vmznds-menu {
	$b: &;

	position: absolute;
	width: 100%;
	transition: {
		property: opacity transform;
		duration: 400ms;
		timing-function: ease;
	}
	overflow: auto;
	z-index: 90000;
	transform: translateX(-50%);
	opacity: 0;
	background-color: #fff;
	pointer-events: none;
	user-focus: none;

	.ms3-wrapper--fullscreen & {
		height: 100%;
	}

	.ms3-wrapper--mobile & {
		width: calc(100% - #{2 * $containerMarginHorizontallyInner});
		padding-bottom: 18px;
	}

	@at-root {
		.vmznds-content-box,
		.vmznds-panel-box__box,
		.ms3-main-container,
		.ms3-additional-container {
			transition: {
				property: opacity;
				duration: 400ms;
				timing-function: ease;
			}
		}
	}
	&--open {
		opacity: 1;
		pointer-events: unset;
		user-focus: unset;
		transform: translateX(0);

		& ~ .vmznds-content-box,
		& ~ .vmznds-panel-box__box,
		& ~ .ms3-main-container,
		& ~ .ms3-additional-container {
			 opacity: 0;
		}

	}

	&__list {
		margin: 0;
		padding: 0;
		color: $logoColor;
		list-style: none;
		line-height: 16px;
		border-top: 1px solid $containerBorderColor;

		.vmznds-panel-box__body & {
			border: none;
		}
	}

	&__section-name {
		display: flex;
		color: black;
		text-transform: uppercase;
		font-weight: $medium-font-weight;
		//font-size: 14px;
		line-height: 1;
		text-transform: uppercase;
		margin: 0;
		height: 34px;
		padding: {
			top: 34px - 4px - 14px;
			left: $containerMarginHorizontallyInner;
		}
		border-bottom: 1px solid $containerBorderColor;

		.vmznds-panel-box__body & {
			display: none;
		}
	}

	&__section-list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__entry {
		border-bottom: 1px solid $containerBorderColor;
		font-weight: $medium-font-weight;
		padding: {
			top: 3px;
			bottom: 3px;
		}

		&--active {
		}

		.vmznds-panel-box .vmznds-menu__section-list &:first-child {
			margin-top: 5px;
		}
		.vmznds-panel-box &:last-child {
			border-bottom: none;
		}
	}

	&__entry-link {
		display: block;
		color: $logoColor;
		text-decoration: none;
		padding: {
			left: $containerMarginHorizontallyInner;
			right: $containerMarginHorizontallyInner;
			top: 4px;
			bottom: 3px;
		}
		background-color: transparent;

		&:hover {
			background-color: $linkBgHovered;
			//text-decoration: underline;
		}

		#{$b}__entry--active & {
			background-color: $preselectedBgColor;
			font-weight: $bold-font-weight;
		}
	}
}
