@import "../variables";

.vmznds-feature-details-tooltip {
	$b: &;

	h1 {
		margin: 0 0 2px;
		padding: 0;
		font-weight: $regular-font-weight;
		font-size: 16px;
		line-height: 1.1;
	}

	&--parking {
		* {
			margin: {
				top: 7px;
				bottom: 7px;
			}
		}
	}

	&--cam {
		display: flex;
		flex-direction: row-reverse;
		margin: 0;
		padding: 0;

		img {
			display: block;
			width: 44%;
			margin: 0;
			padding: 0;
		}

		h1 {
			display: block;
			width: 60%;
			margin: 0;
			padding: 0 0 0 $containerMarginHorizontally;
		}
	}

	&--pundr {
		h1 {
			font-weight: $bold-font-weight;
		}

		$font-size: 13px;
		$line-height-ratio: 1.2;

		#{$b} {
			&__occupation {
				display: flex;
				flex-direction: row;
			}

			&__occupation-text {
				flex-grow: 1;
				flex-shrink: 1;
				font-size: $font-size * 1.5;
				line-height: 2 * $font-size + 2 * $font-size * ($line-height-ratio - 1);
				vertical-align: middle;
			}

			&__occupation-percent,
			&__occupation-trend {
				flex-grow: 0;
				flex-shrink: 0;
				font-size: 2 * $font-size + 2 * $font-size * ($line-height-ratio - 1);
				line-height: 2 * $font-size + 2 * $font-size * ($line-height-ratio - 1);
				vertical-align: middle;
			}

			&__occupation-percent {
				padding-right: 10px;
			}

			&__occupation-trend {
				width: $font-size + $font-size * ($line-height-ratio - 1);
			}
		}
	}
}
