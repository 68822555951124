@import "../mixins/table";

.vmznds-content-box__content .ms3-feature-details-content {
	padding-bottom: 2em;

	.ms3-wrapper--mobile & {
		padding-bottom: 1em;
	}

	/* TODO: Check if still neccesary after global css update!!! */
	ol,
	ul,
	table,
	p,
	a .vmznds-list__more-link {
		font-size: 16px !important;
		line-height: 1.5em!important;
	}

	.vmznds__infobox {
		padding: 2em; // Björn 1em, 2em weil die hr breiter ist als der Inhalt, gleichzeitig aber schmäler als die Box
		margin: 1.5em 0 2em;
		background: none repeat scroll 0 0 $linkBgHovered;
		border-bottom: 1px solid $borderColorA;
		border-top: 1px solid $borderColorA;

		p {
			line-height: 1.4em;
		}

		&--footer {
			margin-top: 3em !important;
		}

		// at-root ist nötig weil & bereit von ganz oben her Unterklassen und BEM-Modifier enthält
		@at-root .vmznds-content__details--withNunav .vmznds__infobox:last-child:not(.vmznds__infobox--nunav-link) {
			margin-bottom: 0;
			border-bottom: none;
			padding-bottom: 1em;
		}
		&.vmznds__infobox--nunav-link {
			margin-top: 0;
			border-top: none;
			padding-top: 0;
			hr {
				margin-top: 0;
			}
		}

		hr {
			margin: 2em -1em;
			border-top: none;
			border-right: none;
			border-left: none;
			border-bottom: 1px solid $borderColorA;
			height: 0;
		}
	}

	// Streifenhörnchen-Polizei
	@at-root .vmznds__infobox:empty {
		display: none;
	}

	// alter Code:
	.vmznds__highlight {
		color: #000000;
		box-shadow: 4px 0 0 yellow, -3px 0 0 yellow;
		background: yellow;
		font-weight: $medium-font-weight !important;
	}

	.vmznds-state--1 {
		background: $vmzRed;
		color: #fff;
		box-shadow: 4px 0 0 $vmzRed, -3px 0 0 $vmzRed;
	}

	.vmznds-state--2 {
		color: #fff;
		box-shadow: 4px 0 0 $vmzOrange, -3px 0 0 $vmzOrange;
		background: $vmzOrange;
	}

	.vmznds-state--3 {
		color: #fff;
		background: $vmzGreen;
		box-shadow: 4px 0 0 $vmzGreen, -3px 0 0 $vmzGreen;
	}

	/** justify **/
	.text-left {
		text-align: left;
	}

	.text-center {
		text-align: center;
	}

	.text-right {
		text-align: right;
	}

	.text-justify {
		text-align: justify;
	}

	header {
		p {
			font-weight: $medium-font-weight !important;
			margin: 0.4em 0;
		}

		& + .vmznds__infobox {
			margin: .5em 0 !important;
		}

		& + .ce-gallery {
			margin-top: 2em;
		}
	}

	h1,
	h2,
	h3 {
		line-height: normal;
		margin: 0;
		padding: 0;
	}

	h1 {
		font-size: 26px;
		font-weight: $medium-font-weight;
		padding-bottom: 10px;
	}

	h2 {
		margin-top: 1.8em;
		margin-bottom: 0.5em;
		font-weight: $medium-font-weight;
		font-size: 22px;

		& + .vmznds__infobox {
			margin-top: 0!important;
		}
	}

	h3 {
		margin-top: 1em;
		margin-bottom: 0.5em;
		font-weight: $medium-font-weight;
		font-size: 19px;

		& + .vmznds__infobox {
			margin-top: 0!important;
		}

		& + ol li:first-child,
		& + ul li:first-child {
			padding-top: 0 !important;
		}
	}

	h4 {
		margin-top: 0.8em;
		margin-bottom: 0.4em;
		font-weight: $medium-font-weight;
		font-size: 18px;

		& + .vmznds__infobox {
			margin-top: 0!important;
		}
	}

	ul,
	ol {
		padding-left: 1.2em;
		margin: 0 0 1.3em;

		li {
			padding: 1em 0;
			margin: 0;
			height: auto;
			min-height: initial;
			line-height: 1.4em;
			border-bottom:  1px solid $borderColorA;

			&:last-child {
				border-bottom:  0;
			}
		}

		li + li {
			border-top-width: 0;
		}

		ul, ol {
			padding: 0.8em 1.6em;
		}
	}

	a {
		&:hover,
		&:focus {
			text-decoration: underline;
		}

		/** link style **/
		&.external-link-new-window {
			&::after {
				width: 14px;
				height: 14px;
			}
		}

		&.link {
			&-internal,
			&-internal-blank,
			&-download,
			&-external,
			&-external-blank,
			&-mail {
				&::after {
					width: 14px;
					height: 14px;
				}
			}
		}
	}

	strong,
	b {
		font-weight: $medium-font-weight;
	}

	hr {
		margin: 3em 0;
		border-top: none;
		border-right: none;
		border-left: none;
		border-bottom: 1px solid $borderColorA;
		height: 0;
	}


	/**************************************************/
	/** Tabellen
	/** .contenttable = Tabelle aus RTE
	/** .ce-table = Tabelle Contentelement Tabelle
	/**************************************************/

	.contenttable,
	.ce-table {
		@include contenttable;
	}

	.ce-table {
		width: 100%;
	}

	.image-embed-item {
		margin: 3px 0 0;
	}

}

/*******************************************************************
 * Modified version from fluid_styled_content
 *
 * --------------------------Start ------------------------------- */

.vmznds-content-box__content {

	.ce-textpic, .ce-image, .ce-nowrap .ce-bodytext, .ce-gallery, .ce-row, .ce-uploads li, .ce-uploads div {
		overflow: hidden;
	}

	.ce-left .ce-gallery, .ce-column {
		float: left;
	}

	.ce-center .ce-outer {
		position: relative;
		float: right;
		right: 50%;
	}

	.ce-center .ce-inner {
		position: relative;
		float: right;
		right: -50%;
	}

	.ce-right .ce-gallery {
		float: right;
	}

	.ce-gallery figure {
		display: table;
		margin: 0;
	}

	.ce-gallery figcaption {
		display: table-caption;
		caption-side: bottom;
		color: $imageCaptionColor;
		padding: 0.5em;
	}

	.ce-gallery img {
		display: inline-block;
		width: 100%;
		height: auto;
	}

	.ce-gallery iframe {
		border-width: 0;
	}

	.ce-border img,
	.ce-border iframe {
		border: 1px solid $border-color;
		padding: 0;
	}

	.ce-intext.ce-right .ce-gallery, .ce-intext.ce-left .ce-gallery, .ce-above .ce-gallery {
		margin-bottom: 7px;
	}

	.ce-intext.ce-right .ce-gallery {
		margin-left: 7px;
	}

	.ce-intext.ce-left .ce-gallery {
		margin-right: 7px;
	}

	.ce-below .ce-gallery {
		margin-top: 7px;
	}

	.ce-column {
		margin-right: 7px;
	}

	.ce-column:last-child {
		margin-right: 0;
	}

	.ce-row {
		margin-bottom: 7px;
	}

	.ce-row:last-child {
		margin-bottom: 0;
	}

	.ce-above .ce-bodytext {
		clear: both;
	}

	.ce-intext.ce-left ol, .ce-intext.ce-left ul {
		padding-left: 40px;
		overflow: auto;
	}

	.ce-textpic li + li {
		border-top-width: 0 !important;
	}

	.ce-intext .ce-bodytext p:first-of-type {
		margin-top: -2px;
	}
	.ce-intext .ce-bodytext p:last-of-type {
		margin-bottom: -2px;
	}

}

@media screen and (max-width: 800px) {
	.ce-intext .image-embed-item {
		height: auto;
		max-width: 210px
	}
}

@media screen and (max-width: 600px) {
	.ce-intext {

		.image-embed-item {
			max-width: none;
		}

		.ce-gallery {
			float: none !important;
			margin: 0 !important;

			.ce-row {
				margin-bottom: 7px;
			}
		}
	}
}

/* -------------------------- End ------------------------------- */

