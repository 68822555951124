@import "../variables";

.ms3-tag-switcher {
	display: flex;
	flex-wrap: wrap;

	.ms3-layer-switcher__entry {
		display: inline-block;
		background: transparent;
		border-top: 0;
	}

	.ms3-layer-switcher__status {
		display: none;
	}

	.ms3-switcher-header {
		display: inline-block;
		margin: 0;
		padding: 0;
		line-height: 1;
		border-top: 1px solid transparent;

		&--active {
			background: $backgroundColorC;
			color: $foregroundColorC;
			border-top-color: $borderColorA;
		}
	}

	.ms3-switcher-header__count,
	.ms3-layer-switcher__entry__count {
		//height: 19px;
		padding: 0px 5px 1px;
		margin: 0 0 0 3px;

		//line-height: 16px;
		text-align: center;
		//font-size: 12px;

		border: 1px solid $backgroundColorC--hover;
		border-radius: 10px;
		color: $borderColorA;
		background: $backgroundColorC--hover;
	}

	.ms3-layer-switcher__entries {
		order: 999999;
		flex-basis: 100%;
		padding-left: 5px;
		background: $backgroundColorC;
	}

	.ms3-switcher-header--inactive + .ms3-layer-switcher__entries {
		display: none;
	}

	.ms3-layer-switcher__button {
		display: inline-block;
		margin: 0;
		padding: 0 6px;


		background: transparent; // FIXME don't use const value
		color: #80abcc; // FIXME don't use const value

		font-size: 13px;
		font-weight: $bold-font-weight;
		line-height: 17px;
		vertical-align: top;

		border: {
			width: 1px;
			style: solid;
			color: transparent;
			radius: 0;
		}

		cursor: pointer;
	}

	.ms3-layer-switcher__entry .ms3-layer-switcher__button--active {
		background: #004685;
		color: #fff;
		font-weight: $bold-font-weight;

		border: {
			color: #80abcc;
			// unteres für die Tags nicht für die Tag-Gruppen
			width: 1px;
			radius: 6px !important;
		}
	}

	.ms3-layer-switcher__entry-count {
		//height: 19px;
		line-height: 16px;
		text-align: center;
		font-size: 13px;
		padding: 2px 5px;
		margin: 0 0 0 3px;
		border-radius: 10px;

		border: 1px solid #00386d;
		color: #80abcc;
		background: #00386d;
	}
}
