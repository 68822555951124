@import "../variables";

.vmznds-feature-details {
	h1 {
		margin: {
			top: 6px;
			bottom: 0px;
		}
		padding: 0;
		font-weight: $bold-font-weight;
		font-size: 20px;
		line-height: 22px;
		letter-spacing: 0;
	}


	&--parking {
		white-space: normal;

		h2 {
			margin: {
				top: 15px;
				bottom: 7px;
			}

			font-size: 120%;
			line-height: 120%;
		}
	}

	&--cam {
		white-space: normal;

		// first image
		> img {
			display: block;
			width: calc(100% + #{2 * $containerMarginHorizontallyInner});
			margin: {
				left: -$containerMarginHorizontallyInner;
				right: -$containerMarginHorizontallyInner;
			}
		}

		.additional-images {
			display: flex;
			flex-wrap: wrap;

			> figure {
				margin-right: $ms3-verticalPanelPadding;
				min-width: 90px;
				max-width: 190px;
				width: calc(50% - 7.5px);

				> img {
					width: 100%;
					filter: grayscale(100%);
				}
			}
		}

		h1 {
			margin-bottom: 20px;
		}

		.ms3-wrapper--fullscreen & {
			margin: {
				left: -$containerMarginHorizontallyInner;
				right: -$containerMarginHorizontallyInner;
			}

			h1, h2 {
				margin: {
					left: $containerMarginHorizontallyInner;
					right: $containerMarginHorizontallyInner;
				}
			}

			// first image
			> img {
				margin: 0;
				width: 100%;
			}

			.additional-images {
				> figure {
					vertical-align: top;
					width: calc(50% - #{0.5 * $containerMarginHorizontallyInner});
					margin: 0;
					min-width: unset;
					max-width: unset;

					&:nth-child(even) {
						margin-left: $containerMarginHorizontallyInner;
					}
				}
			}
		}
	}

	&--pundr {
		b {
			font-weight: $medium-font-weight;
		}
	}

	&--prerouting__main {
		h1 {
			margin: 0;
			font-weight: $bold-font-weight;
			font-size: 14px;
			line-height: 19px;
		}
		p {
			font-weight: $regular-font-weight;
			margin: $containerMarginVerticallyInner 0 0;
		}
	}
}
