@import "../variables";

.vmznds-list-one-cameras {
	&__overview-toggle-button {
		margin: 0;
		padding: $containerMarginVertically $containerMarginHorizontallyInner; // Vertically mit Absicht größer
		cursor: pointer;
		color: $logoColor;
		font-weight: $medium-font-weight;

		&:hover {
			text-decoration: underline;
		}
	}

	&__list-item-wrapper {
	}

	&__list-item {
		width: 100%;
		display: flex;
		flex-direction: row;
	}

	&__list-item-img-wrapper-wrapper {
		flex-shrink: 0;
		flex-grow: 0;
		width: 40%;

		.ms3-wrapper--mobile & {
			width: 44%;
		}
	}

	&__list-item-img-wrapper {
		display: block;
		position: relative;
		width: 100%;
		// 4:3 img ratio
		padding-top: 3/4 * 100%;
	}

	&__list-item-img {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		border: 1px solid $containerBorderColor;
	}

	&__list-item-headline {
		display: block;
		flex-shrink: 0;
		flex-grow: 0;
		overflow-wrap: break-word;
		margin: 0;
		padding: 0;
		padding-left: $containerMarginHorizontallyInner;
		font-size: 16px;
		line-height: 1.25;
		font-weight: $medium-font-weight;
		letter-spacing: -0.04em;
		width: 60%;

		.ms3-wrapper--mobile & {
			padding-left: 2 * $containerMarginHorizontallyInner; // double the distance to the image
			width: 56%;
		}
	}
}
