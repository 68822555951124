@import "../variables";
@import "../mixins/list-group-name";

.ms3-list {
	$b: &;

	/* NOTE: using double selector to increase specificity to overwrite ui base css */
	.ms3-wrapper & {
		margin: 0;
		padding: {
			top: 5px;
			left: 0;
			right: 0;
			bottom: 0;
		};

		background-color: $backgroundColorC;
		color: $foregroundColorB;

		list-style: none;

		&__item {
			position: relative;

			display: flex;
			flex-wrap: wrap;
			min-height: 64px;
			margin: 0;
			padding: $containerMarginVerticallyInner $containerMarginHorizontallyInner;
			border: 0;

			color: $foregroundColorC;
			background: $backgroundColorC;

			align-items: center;
			overflow: hidden;

			> a {
				display: block;
				width: 100%;

				text-decoration: none;
			}

			&:first-child:not(#{$b}__item--selected) {
				border-top-color: transparent;
			}

			&--selectable {
				color: $foregroundColorC !important;
				cursor: pointer;
			}

			&--selected,
			&--highlight {
				color: $foregroundColorC !important;
				background-color: $linkBgHovered !important;
			}

			//// TODO prüfen: gibt es .ms3-list unterhalb von ms3-list__item--selected überhaupt ... oder fehlt da ein "&", was aber bedeutet der Block hier ist überflüssig
			//&--selected {
			//	.ms3-list {
			//		&__link {
			//			color: white !important;
			//		}
			//
			//		&__details {
			//			display: none;
			//		}
			//	}
			//}

			&--selectable {
				cursor: pointer !important;
			}

			&--highlight {
				background-color: $linkBgHovered !important;
			}

			&--preselected {
				background-color: $preselectedBgColor !important;
			}


			.vmznds-content-box__content & {
				padding: 0;

				> * {
					padding-right: $containerMarginHorizontallyInner;
					padding-left: $containerMarginHorizontallyInner;
				}

				> *:first-child {
					padding-top: $containerMarginVerticallyInner;
				}

				> *:last-child {
					padding-bottom: $containerMarginVerticallyInner;
				}
			}

			& + #{$b}__item {
				border-top: {
					style: solid;
					width: 1px;
					color: $containerBorderColor;
				}
			}
		}

		&__filter-box {
			width: 100%;
			max-width: none;
			padding: $containerMarginVerticallyInner 0;
		}

		&__group {
			@include vmznds-list-group-name;

			.ms3-list + & {
				border-top: 1px solid $containerBorderColor;
			}

			&:last-child {
				border-bottom: none;
			}
		}

		&__empty {
			padding: $containerMarginHorizontallyInner;
			margin: 0;
		}

		&__link {
			text-decoration: none;
		}

		// p+r gauge
		&__info {
			position: absolute;
			top: 12px;
			right: 4px;
			margin: 0;
		}

		&__main,
		&__icon {
			width: auto;
			flex-grow: 0;
		}


		&__icon {
			float: unset; // unset ui

			position: absolute;
			right: 0;
			top: 6px;

			width: 64px;
			padding: 0;

			// fix re-flowing of list items because of icon image size
			img {
				width: 40px;
				height: 40px;
				margin-left: 24px; // move to the right of the 64px wide area
			}

			.ms3-feature-details-content & {
				margin-top: 0;
			}

			&--id-pundr {
				top: 2px;
				right: 9px;
				width: 44px;
				img {
					margin-left: 0;
				}
			}
		}


		&__icon + #{$b}__main {
			max-width: calc(100% - 47px);
		}

		&__main {
			display: block;

			margin: 0;
			padding: 0;

			flex-grow: 1;

			white-space: unset;
			font-weight: $medium-font-weight;

			// wahrscheinlich wirkungslos weil & gleich .ms3-list-wrapper .ms3-list__main und das nie innerhalb des .ms3-list-wrapper vorkommt
			//.ms3-feature-details-content & {
			//	max-width: calc(100% - 47px);
			//}

			// Trias: damit sich Texte bei kurzen Überschriften nicht überlagern
			&--trias {
				h1 {
					min-height: 38px;
				}
			}
		}
	}

	.vmznds-mapsight-embed-wrapper--list-only & {
		border-top-style: none;
	}

	.js .ms3-panel--docked-left & {
		padding-top: 0;
		border-top: none;
	}

	.vmznds-box & {
		max-width: none;
	}
}
