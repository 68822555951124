// define responsive interaction of panel boxes to each other

// Grunddesign
.vmznds-panel-boxes {
	display: flex;
	position: relative;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
	overflow: auto;

	// Specials für konkrete Seiten

	// Platz für sticky-by-css pre-routing-modality und wiederherstellen des dadurch verdeckten Headline-Borders
	&--page-prerouting {
		.vmznds-panel-box__headline-wrapper {
			border-bottom: 1px solid $borderColorA;
		}
		.vmznds-panel-box__body {
			margin-top: 51px;
		}
	}
}


// Anordnung der Boxen auf der Startseite


//// INFO Optional: Immer mit Pfeil im Kopf, auch wenn Link darunter
//.vmznds-panel-boxes--page-home {
//	@include panel-box-heading-arrow(-1px);
//}


// TODO nochmal durchdeklinieren und dazu im Browser setzen: .ms3-list__item { height: 140px; }


// limit list two (if there's list-two there are urgent boxes), remaining space goes to list-one
.vmznds-panel-box--key-list-two {
	.ms3-list__item {
		display: none;
	}
	.ms3-list__item:first-child,
	.ms3-list__item:nth-child(2),
	.ms3-list__item:nth-child(3),
	.ms3-additional-container .vmznds-panel-boxes--extra-0 & .ms3-list__item:nth-child(4) {
		display: flex;
	}
}
@media screen and (min-height: 1440px) {
	.vmznds-panel-box--key-list-two {
		.ms3-wrapper--fullscreen .vmznds-panel-boxes--extra-1 & .ms3-list__item:nth-child(4),
		.ms3-wrapper--fullscreen .vmznds-panel-boxes--extra-0 & .ms3-list__item:nth-child(5) {
			display: flex;
		}
	}
}

// mobile
.ms3-additional-container {
	.vmznds-panel-boxes--with-extra {
		.vmznds-panel-box--key-main-list {
			.ms3-list__item {
				display: none;
			}
			.ms3-list__item:first-child,
			.ms3-list__item:nth-child(2),
			.ms3-list__item:nth-child(3) {
				display: flex;
			}
		}
	}
	.vmznds-panel-boxes--with-extra.vmznds-panel-boxes--extra-0 .vmznds-panel-box--key-main-list .ms3-list__item:nth-child(4),
	.vmznds-panel-boxes--with-extra.vmznds-panel-boxes--extra-1 .vmznds-panel-box--key-main-list .ms3-list__item:nth-child(4),
	.vmznds-panel-boxes--with-extra.vmznds-panel-boxes--extra-0 .vmznds-panel-box--key-main-list .ms3-list__item:nth-child(5) {
		display: flex;
	}
}


.vmznds-panel-box__headline-wrapper:last-child /* implicit :first-child, too, → only one child */ {
	@include panel-box-heading-arrow;
}


// desktop
.ms3-wrapper--fullscreen {
	.vmznds-panel-boxes--extra-3 {
		.vmznds-panel-box__box--key-list-two .vmznds-panel-box__body {
			display: none;
		}
		@media screen and (max-height: 1023px) {
			.vmznds-panel-box__box--key-list-two {
				.vmznds-panel-box__footer {
					display: none;
				}
				@include panel-box-heading-arrow;
			}
		}
		@media screen and (max-height: 950px) {
			.vmznds-panel-box__box--key-prerouting {
				display: none;
			}
		}
		@media screen and (max-height: 880px) {
			.vmznds-panel-box__box--key-main-list .vmznds-panel-box__body {
				display: none;
			}
		}
		@media screen and (max-height: 800px) {
			//@include panel-box-hide-all-footer;
			//.vmznds-panel-box__box--key-main-list {
			//	@include panel-box-heading-arrow;
			//}
		  .vmznds-panel-box__box--key-list-two {
			display: none;
		  }
		}
	}
	.vmznds-panel-boxes--extra-2 {
		.vmznds-panel-box__box--key-list-two .ms3-list__item:nth-child(3) {
			display: none;
		}
		@media screen and (max-height: 1179px) {
			.vmznds-panel-box__box--key-list-two .vmznds-panel-box__body {
				display: none;
			}
		}
		@media screen and (max-height: 940px) {
			.vmznds-panel-box__box--key-list-two {
				.vmznds-panel-box__footer {
					display: none;
				}
				@include panel-box-heading-arrow;
			}
		}
		@media screen and (max-height: 830px) {
			.vmznds-panel-box__box--key-main-list .vmznds-panel-box__body {
				display: none;
			}
		}
	    @media screen and (max-height: 799px) {
		    .vmznds-panel-box__box--key-prerouting {
		        display: none;
		    }
	    }

	  //@media screen and (max-height: 800px) {
		//	@include panel-box-hide-all-footer;
		//	.vmznds-panel-box__box--key-main-list {
		//		@include panel-box-heading-arrow;
		//	}
		//}
	}
	.vmznds-panel-boxes--extra-1 {
		@media screen and (max-height: 1179px) {
			.vmznds-panel-box__box--key-list-two .ms3-list__item:nth-child(3) {
				display: none;
			}
		}
		@media screen and (max-height: 1079px) {
			.vmznds-panel-box__box--key-list-two .vmznds-panel-box__body {
				display: none;
			}
		}
		@media screen and (max-height: 1023px) {
			.vmznds-panel-box__box--key-list-two {
				.vmznds-panel-box__footer {
					display: none;
				}
				@include panel-box-heading-arrow;
			}
		}
		@media screen and (max-height: 799px) {
			//.vmznds-panel-box__box--key-prerouting,
			.vmznds-panel-box__box--key-main-list .vmznds-panel-box__body {
				display: none;
			}
		}
		@media screen and (max-height: 700px) {
			@include panel-box-hide-all-footer;
			.vmznds-panel-box__box--key-main-list {
				@include panel-box-heading-arrow;
			}
		}
	}
	.vmznds-panel-boxes--extra-0 {
		@media screen and (max-height: 1079px) {
			.vmznds-panel-box__box--key-list-two .ms3-list__item:nth-child(3) {
				display: none;
			}
		}
		@media screen and (max-height: 920px) {
			.vmznds-panel-box__box--key-list-two .vmznds-panel-box__body {
				display: none;
			}
		}
		@media screen and (max-height: 800px) {
			.vmznds-panel-box__box--key-main-list .vmznds-panel-box__body {
				display: none;
			}
		}
		@media screen and (max-height: 630px) {
			@include panel-box-hide-all-footer;
			.vmznds-panel-box__box--key-main-list {
				@include panel-box-heading-arrow;
			}
		}
	}
}

// desktop: hide list items that aren't completely visible
.ms3-wrapper--fullscreen {
	.vmznds-panel-boxes--extra-3,
	.vmznds-panel-boxes--extra-2,
	.vmznds-panel-boxes--extra-1,
	.vmznds-panel-boxes--extra-0 {
		.vmznds-main-panel-container__main-list-wrapper,
		.ms3-list-wrapper {
			height: 100%;
		}
		.ms3-list {
			height: 100%;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			overflow: hidden;
		}
		.ms3-list__item {
			width: 100%;
		}
	}
}
