.ms3-pre-routing-note {
	p {
		margin-top: 0;
	}

	ul, li {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		padding-left: 50px;
	}

	li + li {
		margin-top: $containerMarginVerticallyInner;
	}

	a {
		position: relative;
		line-height: 20px; // 2*20px = 40px vom icon
		font-size: $base-font-size;
		background-position: calc(100% + 3px) 2px;
	}

	img {
		position: absolute;
		top: 3px;
		left: -50px;
	}
}
