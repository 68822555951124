/* ==========================================================================
   Block: Hint
   ========================================================================== */

[data-hint]:not(a[href]) {
	cursor: default;

	a[href] & {
		cursor: inherit;
	}
}
