.vmznds-pt {
	&-type {
		display: inline-block;
		height: 19px;
		width: 19px;
		margin: 1px;
		vertical-align: bottom;
		background: {
			repeat: no-repeat;
			position: center;
			size: contain;
		}

		&--u {
			background-image: url(../img/icons/pt/u_bahn.png);
		}
		&--s {
			background-image: url(../img/icons/pt/s_bahn.svg);
		}
		&--bus {
			background-image: url(../img/icons/pt/bus.png);
		}
		&--train,
		&--rb,
		&--re {
			// beim Üstra-Png entfällt width
			width: #{21*1746/2048}px;
			background-image: url(../img/icons/pt/zug.svg);
		}
		&--db {
			width: #{21*335/235}px;
			background-image: url(../img/icons/pt/db.svg);
		}
		&--ast {
			$ast-color: #FFE33A;
			width: 57px;
			padding-left: 20px;
			margin-left: 20px;
			position: relative;

			font-weight: $bold-font-weight;
			font-size: 15px;
			line-height: 21px;

			background: {
				image: url(../img/icons/pt/phone.svg);
				size: 15px 15px;
				position-x: 3px;
				color: $ast-color;
			}

			&:before,
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				height: 19px;
				border-width: 10.5px;
				border-style: solid;
			}

			&:before {
				left: -20px;
				border-color: transparent $ast-color transparent transparent;
			}
			&:after {
				right: -19px;
				border-color: transparent transparent transparent $ast-color;
			}
		}

	}

	&-line {
		display: inline-block;
		margin: 1px 2px;
		line-height: 17px;

		// BUS Linie absichtlich schwarzweiß, weil das lila Bus-Logo davor steht
		&--mode-bus {
			font-weight: bold;
			white-space: nowrap;
			//font-size: 85%;
			padding: 0px .4em;
			border: 1px solid black;
			background-color: white;
			-webkit-border-radius: 1ex;
			-moz-border-radius: 1ex;
			border-radius: 1ex;
			color: black;
		}

		&--mode-u {
			font-weight: bold;
			white-space: nowrap;
			//font-size: 85%;
			padding: 0px .4em;
			border: 1px solid black;
			background-color: white;
			-webkit-border-radius: 0ex;
			-moz-border-radius: 0ex;
			border-radius: 0ex;
		}
		&--line-u-1,
		&--line-u-2,
		&--line-u-8,
		&--line-u-18 {
			border-color: red;
		}
		&--line-u-3,
		&--line-u-7,
		&--line-u-9 {
			border-color: blue;
		}
		&--line-u-4,
		&--line-u-5,
		&--line-u-6,
		&--line-u-11,
		&--line-u-16 {
			border-color: orange;
		}
		&--line-u-10,
		&--line-u-17 {
			border-color: green;
		}
		&--line-u-16,
		&--line-u-18 {
			background-color: lightgrey;
		}

		&--mode-s {
			font-weight: bold;
			white-space: nowrap;
			//font-size: 85%;
			font-size: 14px;
			padding: 0px .4em;
			border: 1px solid #836caa;
			background-color: #836caa;
			-webkit-border-radius: 2ex;
			-moz-border-radius: 2ex;
			border-radius: 2ex;
			color: white;
		}

		&--line-s-1,
		&--line-s-s1, {
			border-color: #836caa;
			background-color: #836caa;
		}
		&--line-s-2,
		&--line-s-s2,
		&--line-s-21,
		&--line-s-s21, {
			border-color: #007a3c;
			background-color: #007a3c;
		}
		&--line-s-3,
		&--line-s-s3, {
			border-color: #cb68a6;
			background-color: #cb68a6;
		}
		&--line-s-4,
		&--line-s-s4, {
			border-color: #9a2a47;
			background-color: #9a2a47;
		}
		&--line-s-5,
		&--line-s-s5,
		&--line-s-51,
		&--line-s-s51, {
			border-color: #f18700;
			background-color: #f18700;
		}
		&--line-s-6,
		&--line-s-s6, {
			border-color: #004f9e;
			background-color: #004f9e;
		}
		&--line-s-7,
		&--line-s-s7, {
			border-color: #afca26;
			background-color: #afca26;
		}
		&--line-s-8,
		&--line-s-s8, {
			border-color: #009ad9;
			background-color: #009ad9;
		}

		&--mode-rb {
			font-weight: bold;
			white-space: nowrap;
			//font-size: 85%;
			padding: 0px .4em;
			border: 1px solid #707176; //black;
			background-color: #707176; //black;
			-webkit-border-radius: 1ex;
			-moz-border-radius: 1ex;
			border-radius: 1ex;
			color: white;
		}

		&--mode-re {
			font-weight: bold;
			white-space: nowrap;
			//font-size: 85%;
			padding: 0px .4em;
			border: 1px solid #707176; //black;
			background-color: #707176; //black;
			-webkit-border-radius: 1ex;
			-moz-border-radius: 1ex;
			border-radius: 1ex;
			color: white;
		}

		&--mode-train {
			font-weight: $medium-font-weight;
		}

		//&--mode-db {
		//	height: 21px;
		//	width: #{21*335/235}px;
		//	vertical-align: bottom;
		//	background: {
		//		repeat: no-repeat;
		//		position: center;
		//		size: contain;
		//		image: url(../img/icons/pt/db.svg);
		//	}
		//}
	}

}
