.vmznds-logo {
	$b: &;

	flex-shrink: 0;

	&--small-over-map {

	}

	&__link {
		position: relative;
		display: block;
		height: fit-content;

		&:before {
			opacity: 0;
			content: 'zur Startseite';
			white-space: nowrap;
			padding: 10px 11px 11px;
			position: absolute;
			top: -23px;
			left: 61px;
			border: 1px solid black;
			border-radius: 2px;
			background-color: white
		}
		@at-root a#{&}:hover:before {
			opacity: 1;
		}

		#{$b}--small-over-map & {
			display: inline-block;
			pointer-events: all;

			&:before {
				top: 31px;
			}
		}
	}

	&__img {
		display: inline-block;
		// die svg hat nicht die passende Größe und außerdem einen Rand, der weg-korrigiert werden muss
		margin-left: -4px;
		margin-top: -2px;
		width: 249px;
		height: 130px;
		background-size: 249px 130px;
		background-image: url(../img/logo.svg?v=2021);

		#{$b}--small-over-map & {
			margin: 0;
			background-image: url(../img/logo-small.svg?v=2021);
			width: 69px;
			height: 71px;
			background-size: 69px 71px;
		}

		.ms3-wrapper--mobile & {
			margin: 0;
			// padding-bottom hack https://css-tricks.com/scale-svg/
			box-sizing: content-box;
			width: 100%;
			height: 0;
			padding: 0;
			padding-bottom: calc(100% * 68 / 380);
			background-size: cover;
			background-image: url(../img/logo-wide.svg?v=2021);

			&:before {
				left: 61px;
			}
		}
	}
}
