.ms3-modal__inner .ms3-dialog-close-button,
.ms3-dialog-close-button,
.vmznds-close-button {
	position: absolute;
	display: block;
	width: 26px;
	height: 26px;
	margin: 0;
	padding: 0;
	border: none;
	background-color: transparent;
	cursor: pointer;
	border: none;
	border-color: white; // there's a transition on it
	border-radius: 0 4px 0 4px;
	background-image: none;


	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 12px;
		right: 6px;
		display: block;
		width: 14px;
		height: 2px;
		background: $mapControlForegroundColorA;
		border-radius: 1px;
		transform: rotate(-45deg);
	}

	&::after {
		transform: rotate(45deg);
	}

	&:hover {
		background-color: $linkBgHovered;
		border-bottom: 1px solid $containerBorderColor;
		border-left: 1px solid $containerBorderColor;

		.vmznds-panel-box--urgent-school & {
			background-color: scale_color($linkBgHovered, $alpha: -50%);
		}
	}
}

.vmznds-close-button {
	top: 1px;
	right: 1px;
}


.ms3-modal__inner .ms3-dialog-close-button {
	background-image: none;
	position: sticky;
	top: 3px;
	left: calc(100% - 18px);

	&:hover {
		border: 1px solid $containerBorderColor;
		border-radius: 4px;
	}

	&::before,
	&::after {
		right: 5px;
	}
}
