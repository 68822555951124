@import "../variables";

.ms3-trip-stop-input {
	position: relative;

	&__input {
		display: block;
	}

	.react-autosuggest__suggestions-container {
		&--open {
			border: 1px solid #ddd;
			border-top: 0;
		}
	}

	.react-autosuggest__suggestions-list {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&__suggestions {
		position: absolute;
		margin: 0;
		padding: 0;
		list-style: none;
		background-color: #99f;
		z-index: 1000;
	}

	&__suggestion {
		display: block;
		cursor: pointer;
		padding: 10px;
		color: $foregroundColorC;
		background-color: $backgroundColorC;
		border-top: 1px solid $borderColorA;

		.react-autosuggest__suggestion--highlighted > &,
		&:hover {
			background-color: $backgroundColorB;
		}

		& + & {
			border-top: {
				style: solid;
				width: 1px;
				color: $listItemBorderColor;
			}
		}

		&--event {
			background-image: url(/assets/img/icons/navigation/modality-ticket.svg);
			background-repeat: no-repeat;
			background-position: 0 50%;
			background-size: 30px;
			padding-left: 32px;
		}
	}
}
