.vmznds-rz-station-tags {
	list-style: none;
	margin: 0;
	padding: 0;
}

.vmznds-rz-station-tag {
	display: inline-flex;
	margin: 0;
	padding: 0;
	align-items: center;
	white-space: nowrap;
	word-break: keep-all;

	&:not(:last-child)::after {
		break-before: avoid;
		content: ", ";
		margin-right: 6px;
	}

	i {
		display: block;
		height: 8px;
		width: 8px;
		margin-right: 4px;
		border-radius: 8px;
	}
}
