.vmznds-panel-option {
	$b: &;

	& + & {
		border-top: {
			style: solid;
			width: 1px;
			color: $containerBorderColor;
		}
	}

	&--active {
	}

	&__header {
		display: flex;
		flex-direction: row;
		padding: {
			left: $containerMarginHorizontallyInner;
		}
		cursor: pointer;

		#{$b}--active & {
			border-bottom: {
				style: solid;
				width: 1px;
				color: $containerBorderColor;
			}
		}
	}

	&__headline {
		flex-grow: 1;
		flex-shrink: 1;
		padding-top: 12px;
		line-height: 16px;
		font-size: 13px;
	}

	&__button {
		flex-grow: 0;
		flex-shrink: 0;
		display: block;
		width: 30px;
		height: 20px;
		margin-top: 10px;
		margin-right: 0;
		margin-left: 0;
		margin-bottom: 0;
		padding: 0;
		border: 0;
		background-color: transparent;
		background-image: url("/assets/img/panel-option-inactive.svg?v=2021");
		background-position: center center;

		#{$b}--active & {
			background-image: url("/assets/img/panel-option-active.svg?v=2021");
		}
	}

	&__body {
		padding: 8px;
	}
}
