
.vmznds-box--webcam {
	.vmznds-webcam {
		width: 50%;
		padding: 3px 6px;
		margin: 0;
		position: relative;
		display: inline-block;
		margin-right: -4px;
		vertical-align: top;

		img {
			border: 1px solid black;
			width: 100%;
		}

		figcaption {
			background-color: black;
			bottom: 8%;
			color: white;
			display: inline;
			left: 6px;
			min-width: 45%;
			max-width: 90%;
			padding: 0.2em 4px;
			position: absolute;
		}
	}

	.vmznds-webcam--big {
		display: block;
		width: 100%;
	}
}


.vmznds-webcams {
	padding: 5px;
	margin: 2% 0;

	&__item {
		position: relative;
		display: inline-block;
		vertical-align: top;
		margin: 0 -4px 0 0;
		padding: 6px;
		width: 25%;
		min-height: 220px;

		figcaption {
			display: block;
		}
	}

	&__image {
		width: 100%;
		box-shadow: 3px 3px 4px -2px #000;
		border: 1px solid #333;
		margin-bottom: 7px;
	}

	&__preview {
		position: absolute;
		right: 0;
		top: 0;
		color: black;
		background: gray;
		padding: 2px;
		font-size: 11px;
		visibility: hidden;

		img {
			max-width: 120px;
			height: auto;
		}
	}

	&__item:hover &__preview {
		visibility: visible !important;
	}

	.js &__save-button {
		display: none;
	}
}


.vmznds--list-view .vmznds-list--poi .vmznds-webcam {
	padding: 3px 6px;
	margin: 0;

	&, img {
		width: 100%;
	}
}

.vmznds--map-view .vmznds-poi__details .vmznds-webcam img {
	border: 1px solid #000000;
	width: 100%;
	height: auto;
}

@include vmznds-step(3) {
	.vmznds-box--webcam {
		float: right;
	}
}
