.vmznds-qr-link {
	.vmznds-content-box__content .ms3-feature-details-content & p {
		margin: 0;

		a {
			display: block;
			margin: 0.4em 0 0.6em 0;
		}

		b {
			display: block;
			margin-top: 0.8em;
			margin-bottom: 0.8em;
			font-weight: 600;
			font-size: 18px;
			line-height: 19px;
		}
	}

	// TODO @media < 500px Breit → 100% background-size
	// TODO bz bearbeiten und Ränder links und rechts entfernen

	&__bikeCitizens {
		padding-top: 100px;
		background-size: 500px;
		background-position: -48px 0px;
		background-repeat: no-repeat;
		background-image: url(../img/bikecitizens_logo_qr.png);

		@media (min-width: #{$mobileMaxWidth + 1px}) and (max-width: 885px) {
			background-size: calc(100vw - 410px);
			background-position-x: 54%;
		}
		@media (max-width: 550px) {
			background-size: calc(100vw - 100px);
			background-position-x: 80%;
		}
	}
	&__nunav {
		padding-top: 100px;
		background-size: 500px;
		background-position: 0px 4px;
		background-repeat: no-repeat;
		background-image: url(../img/nunav_logo_qr.svg);

		@media (min-width: #{$mobileMaxWidth + 1px}) and (max-width: 980px) {
			background-size: calc(100vw - 500px);
		}
		@media (max-width: 640px) {
			background-size: calc(100vw - 140px);
		}
	}
}
