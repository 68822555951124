.ms3-layer-switcher__status {
	background-size: 40px 40px;
	background-position: -11px -11px;
}


.ms3-layer-switcher-overlay .ms3-layer-switcher-container {
	top: 7px;
	right: 13px;
	width: 212px;

	border-width: 1px;
	border-radius: $containerBorderRadius;
	border-color: $containerBorderColor;
	border-style: solid;

	// according to _panel-box.scss &__headline-wrapper und &__headline
	h3 {
		height: 38px;
		$normal-font-size: 14px;
		font-weight: $medium-font-weight;
		font-size: $normal-font-size;
		line-height: 1;
		text-transform: uppercase;
		margin: 0;
		padding: (38px - 4px - $normal-font-size) $containerMarginHorizontallyInner 4px;
		border-bottom: 1px solid $containerBorderColor;
	}

	.ms3-layer-switcher__status {
		margin: 0px 7px 0px 0;
	}
	.ms3-layer-switcher__button {
		color: $logoColor;
		line-height: 1;
	}

	.ms3-layer-switcher__entry--locked {
		.ms3-layer-switcher__button {
			color: $ms3-secondaryColor; // #a9a9a9
		}
		.ms3-layer-switcher__status--active {
			background-image: url("../img/mapsight-ui/check-grey.svg?v=2021");
		}
		.ms3-layer-switcher__status--inactive {
			background-image:  url("../img/mapsight-ui/check-grey-empty.svg?v=2021");
		}
	}

	.ms3-layer-switcher__entry--active * {
		font-weight: $bold-font-weight;
	}

	.ms3-layer-switcher__button--active {
		background: white;
	}
	// TODO Schließen-Button according to _panel-box.scss &__headline-close-button
}
