.vmznds-content-cameras {
	&__headline-wrapper {
		display: flex;
	}

	&__headline {
		flex-shrink: 1;
		flex-grow: 1;
		padding: 0;
		margin: 0;
	}

	&__close-button {
		flex-shrink: 0;
		flex-grow: 0;

		.vmznds-close-button {
			top: 0;
			right: 0;
		}
	}

	&__entries {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		& .vmznds-content--loading {
			margin-top: 3 * $containerMarginVerticallyInner;
		}
	}

	&__entry {
		display: block;
		flex-grow: 0;
		flex-shrink: 0;
		padding: $containerMarginVerticallyInner $containerMarginHorizontallyInner;
		cursor: pointer;
		width: 100%;

		@for $colNum from 2 to 10 {
			// 370px = panel width
			// ~400px = entry max width
			@media screen and (min-width: 370px + 400px * ($colNum - 1)) {
				width: 100% / $colNum;
			}
		}

		&:hover {
			background-color: #f2f2f2;
		}
	}

	&__entry-headline {
		margin: {
			left: 0;
			right: 0;
			top: $containerMarginVerticallyInner;
			bottom: $containerMarginVerticallyInner;
		}
		padding: 0;
		font-size: 18px;
		line-height: 1;
		height: 18px;
		font-weight: $regular-font-weight;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__entry-img-wrapper {
		display: block;
		position: relative;
		width: 100%;
		// 4:3 img ratio
		padding-top: 3/4 * 100%;
	}

	&__entry-img {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
}
