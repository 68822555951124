@import "../variables";


.ms3-input-container {
	width: unset;
	max-width: unset;
	margin: 0;
	padding: 0;

	border: none;
	border-radius: 0;
	border-bottom: {
		style: solid;
		width: 1px;
		color: $foregroundColorA;
	}

	background-color: $linkBgHovered; // #e9e9e9;

	&__reset-button {
		background-color: transparent;
		//color: #9b9b9b; // Farbe ist egal, kommt aus dem svg
		margin-top: -1px;
		margin-right: -4px;
	}

	& &--button__icon-geo-location {
		//height: 33px;
		//width: 40px;
		background: transparent url("../img/mapsight-ui/geo-light.svg") no-repeat center;
		//background-size: 20px 20px;
		//background-position: 50% 50%;

		&:hover,
		&:focus {
			background-image: url("../img/mapsight-ui/geo-active.svg");
		}
	}

	&__element {
		box-sizing: content-box;

		display: block;
		height: 30px;
		margin: 0;
		padding: 6px 32px 4px 5px;
		border: 0;

		outline: none;

		color: $foregroundColorA;
		background-color: $linkBgHovered; // #e9e9e9;
		border-radius: 0;

		font-weight: $medium-font-weight;
		font-size: 16px;
		//line-height: 16px;

		&--incomplete:not(:focus) {
			color: $ms3-secondaryColor;
			font-weight: $regular-font-weight;
		}

		// hide "clear" button
		&::-ms-clear,
		&::-ms-reveal {
			display: none;
			width: 0;
			height: 0;
		}

		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			display: none;
		}

		&:hover,
		&:focus {
			color: $foregroundColorA;
			background-color: #dedede;
		}

		&::placeholder {
			color: $foregroundColorA;
			font-weight: $regular-font-weight;
		}
	}

	&__events {
		position: absolute;
		top: 0;
		left: 100%;
		width: 30px;
		background: transparent url(/assets/img/icons/navigation/modality-ticket.svg) no-repeat 2px 1px;
		background-size: 29px;
		height: 100%;
		border: none;

		padding: 0;
		margin: 2px;
		cursor: pointer;

		&--black {
			background-image: url(/assets/img/icons/navigation/modality-ticket-black.svg);
		}
	}

	&--short {
		width: calc(100% - 33px);

		.ms3-trip-stop-input__suggestion {

		}

		.react-autosuggest__suggestions-container--open {
			border-bottom: none;
		}

		.react-autosuggest__suggestion {
			border-right: 1px solid #ddd;
			width: calc(100% + 32px);

			&:last-child {
				border-bottom: 1px solid black;
				margin-bottom: -1px;
			}
		}
	}
}
