@mixin panel-box-heading-arrow-arrow($offset: 0) {
	content: ' ';
	display: block;
	position: absolute;
	right: $containerMarginHorizontallyInner - 1px; // -1px für den optischen Eindruck
	top: #{18px + $offset};
	width: 18px;
	height: 18px;
	background-size: 18px 18px;
	background-image: url("/assets/img/link.svg?v=2021");
	background-position: center -2px;
	background-repeat: no-repeat;
}

@mixin panel-box-heading-arrow($offset: 0) {
	.vmznds-panel-box__headline-inner:after {
		@include panel-box-heading-arrow-arrow($offset);
	}
}

// nur einsetzen wenn die body der boxen ohnehin leer oder schon display:none sind TODO? oder alternative hier den body gleich mit auf display:none;
@mixin panel-box-hide-all-footer() {
	.vmznds-panel-box__footer {
		display: none;
	}
	.vmznds-panel-box__box--key-urgent {
		background-color: $vmzRed;
		@include panel-box-heading-arrow;
	}
	.vmznds-panel-box__box--key-school {
		background-color: $vmzOrange;
		@include panel-box-heading-arrow;
	}
	.vmznds-panel-box__box--key-info_green {
		background-color: $vmzGreen;
		@include panel-box-heading-arrow;
	}
	.vmznds-panel-box__box--key-info_blue {
		background-color: $logoColor;
		@include panel-box-heading-arrow;
	}
}
