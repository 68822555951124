/* ==========================================================================
   Global Typographie
   ========================================================================== */

@font-face {
	font-family: 'HKGrotesk';
	font-weight: 100-400;
	src: url(/assets/fonts/HKGrotesk-Regular.woff2) format("woff2"),
	url(/assets/fonts/HKGrotesk-Regular.woff) format("woff"),
	url(/assets/fonts/HKGrotesk-Regular.otf) format("truetype");
}

//// we do not use 500
//@font-face {
//	font-family: 'HKGrotesk';
//	font-weight: 500;
//	src: url(/assets/fonts/HKGrotesk-Medium.woff2) format("woff2"),
//	url(/assets/fonts/HKGrotesk-Medium.woff) format("woff"),
//	url(/assets/fonts/HKGrotesk-Medium.otf) format("truetype");
//}

@font-face {
	font-family: 'HKGrotesk';
	font-weight: 600;
	src: url(/assets/fonts/HKGrotesk-SemiBold.woff2) format("woff2"),
	url(/assets/fonts/HKGrotesk-SemiBold.woff) format("woff"),
	url(/assets/fonts/HKGrotesk-SemiBold.otf) format("truetype");
}

//// we do not use 700
//@font-face {
//	font-family: 'HKGrotesk';
//	font-weight: 700;
//	src: url(/assets/fonts/HKGrotesk-Bold.woff2) format("woff2"),
//	url(/assets/fonts/HKGrotesk-Bold.woff) format("woff"),
//	url(/assets/fonts/HKGrotesk-Bold.otf) format("truetype");
//}

@font-face {
	font-family: 'HKGrotesk';
	font-weight: 800;
	src: url(/assets/fonts/HKGrotesk-ExtraBold.woff2) format("woff2"),
	url(/assets/fonts/HKGrotesk-ExtraBold.woff) format("woff"),
	url(/assets/fonts/HKGrotesk-ExtraBold.otf) format("truetype");
}


html {
	font-weight: $regular-font-weight;
	font-family: $fontFamilyLight;
	-webkit-font-smoothing: antialiased;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	font-size: $base-font-size;
	line-height: $base-line-height;
	//letter-spacing: -0.01em;   makes WorkSans a little nicer but doesn't help against it's bad kerning
}

b {
	font-weight: $bold-font-weight;
}

a {
	color: inherit;
}

em {
	font-style: normal;
}

h1 {
	font-size: 28px;
	line-height: 38px;
	margin-top: 19px;
	margin-bottom: 19px
}

h2 {
	font-size: 21px;
	line-height: 38px;
	margin-top: 19px;
	margin-bottom: 19px
}

h3 {
	font-size: 16px;
	line-height: 38px;
	margin-top: 19px;
	margin-bottom: 19px
}

h4 {
	font-size: 14px;
	line-height: 19px;
	margin-top: 19px;
	margin-bottom: 19px
}

h5 {
	font-size: 12px;
	line-height: 19px;
	margin-top: 19px;
	margin-bottom: 19px
}

h6 {
	font-size: 9px;
	line-height: 19px;
	margin-top: 19px;
	margin-bottom: 19px
}

p,
pre,
dl,
menu,
ol,
ul,
figure {
	margin: 19px 0
}
