.vmznds-webcam__item {
	position: relative;
	margin: 0;

	.vmznds-webcam__preview {
		position: absolute;
		right: 0;
		top: 0;
		color: #000;
		background: grey;
		padding: 2px;
		font-size: 11px;
		visibility: hidden;
	}

	.vmznds-webcam__preview img {
		max-width: 120px;
		height: auto;
	}

	&:hover .vmznds-webcam__preview {
		visibility: visible!important;
	}


	.vmznds-webcam__item {
		margin: 0;
	}

}
