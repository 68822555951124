@import "../mixins/button";

.vmznds-area-switcher {
	// embedded in map
	.ms3-map-overlay & {
		position: absolute;
		left: 70px;
		right: 70px;
		top: $vmzMapButtonMargin;
		pointer-events: auto;
	}

	// standalone positioned above the map
	.ms3-wrapper > & {
		margin-top: $containerMarginVertically;
		margin-left: 15px;
		margin-right: 15px;
	}

	.ms3-wrapper--mobile > & {
		margin: 0;
	}

	&__list {
		display: flex;
		//justify-content: space-between; // justify nicht erwünscht auf Fullscreen über Karte
		//flex-direction: row;
		list-style: none;
		margin: 0;
		padding: 0;

		.ms3-map-overlay & {
			margin: {
				left: auto;
				right: auto;
			};
			max-width: 616px;
			@media screen and (max-width: (1023px)) {
				max-width: 345px;
			}
		}

		.ms3-wrapper--mobile & {
			display: flex;
			justify-content: space-between;
			flex-direction: row;
		}
	}

	&__entry {
		display: block;
		padding-left: 4px;
		padding-right: 4px;

		.ms3-wrapper--mobile & {
			display: inline-block;
			padding: 0;
		}

	}

	&__link {
		@include vmznds-button;
		width: 146px;

		@media screen and (max-width: (1023px)) {
			width: 78px;
		}

		@media screen and (max-width: (345px)) {
			width: 72px;
		}

		.ms3-wrapper--mobile & {
			padding: 3px 2px;
			@media screen and (min-width: 680px){
				width: 146px;
			}
		}
	}
}
