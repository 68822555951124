/* ==========================================================================
   Trumps: Helper classes
   ========================================================================== */

.ir {
	background-color: transparent;
	border: 0;
	overflow: hidden;
	*text-indent: -9999px;
}

.ir:before {
	content: "";
	display: block;
	width: 0;
	height: 150%;
}

.hidden {
	display: none !important;
	visibility: hidden;
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: absolute;
	width: auto;
}

.invisible {
	visibility: hidden;
}

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	*zoom: 1;
}

.effecthideable {
	transition: opacity 0.3s ease;
}
.effecthidden {
	opacity: 0;
	pointer-events: none;
	* {
		pointer-events: none;
	}
}
