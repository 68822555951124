@import "../variables";
@import "../mixins/steps-and-widths";

.ms3-wrapper {
	//background-color: $backgroundColorA;
	//color: $foregroundColorA;
	transition: max-height 20ms ease-out; // basically disable transition to make transition from/to fullscreen less flickery, but keeping transitionend events

	&--mobile {
		padding-top: $containerMarginVertically;
		padding-right: $containerMarginHorizontally;
		padding-left: $containerMarginHorizontally;
		padding-bottom: 0;

		.ms3-main-container {
			margin: {
				left: -$boxHorizontalGap;
				right: -$boxHorizontalGap;
			}
		}
	}
}

.ms3-wrapper.ms3-wrapper--fullscreen {
  background: none;
}

