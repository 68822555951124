.vmznds-map-overlay-bottom .vmznds-mini-legend {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: $containerMarginVerticallyInner;
	@media (max-width: 665px) {
		// erspart das Reparieren. Die Legende kann über den i-Knopf aufgerufen werden
		display: none;
	}

	.ms3-mini-legend {
		@media (max-width: 1030px) {
			margin: 0 0 0 -30px;
			width: calc(100% + 30px);
		}
		@media (max-width: 1010px) {
			margin: 0 0 0 -90px;
			width: calc(100% + 90px);
		}
		@media (max-width: $mobileMaxWidth) {
			margin: 0;
			width: 100%;
		}

		border: {
			style: solid;
			width: 1px;
			radius: $containerBorderRadius;
			color: $containerBorderColor;
		}

		&__legend, &__select, &__more {
			padding: 2px;
		}

		.ms3-wrapper--mobile & {
			display: none;
		}

		select {
			color: $foregroundColorC;
			background-color: $linkBgHovered;
			//font-size: 12px;
			line-height: 1;
			border: {
				style: solid;
				width: 1px;
				radius: 4px;
				color: $containerBorderColor;
			}
		}

		&__legend {
			padding-left: 6px;
			padding-right: 6px;
			font-weight: $medium-font-weight;

			* {
				font-weight: $medium-font-weight;
			}
		}

		&__more {
			display: none;

			* {
				height: 100%;
				font-weight: $bold-font-weight;
			}

			.ms3-wrapper--fullscreen & {
				display: inline-block;
			}
		}
	}
}
