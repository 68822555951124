@import "../variables";

@mixin vmznds-button() {
	display: block;
	padding: 8px 7px;
	text-align: center;
	text-decoration: none;
	color: $logoColor;
	background-color: #fff;
	border: {
		style: solid;
		width: 1px;
		color: $containerBorderColor;
		radius: $containerBorderRadius;
	}
	font-weight: $bold-font-weight;
	line-height: 17px;
	hyphens: manual;

	cursor: pointer;

	font-size: $h4-font-size;

	@media screen and (max-width: (1140px)) {
		padding-left: 2px;
		padding-right: 2px;
	}

	&--disabled {
		color: #9b9b9b;
	}

	&:hover {
		background-color: $linkBgHovered;
	}

	&--selected,
	&--selected:hover {
		background-color: $logoColor;
		border-color: #fff;
	}
	&--selected {
		color: #fff;
		&:hover {
			color: $linkBgHovered;
		}
	}
}
