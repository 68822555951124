.vmznds-rz-wizard-message {
	padding: 10px;
	margin: 15px 0;
	border: 1px solid #ccc;
	border-radius: 3px;
	color: #333;
	background: #f5f5f5;

	&--error {
		background: #ffe0df;
	}

	&--warn {
		background: #fff9df;
	}
}
