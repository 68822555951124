.vmznds-menu-button {
	$b: &;

	display: block;
	width: calc(100% - 3px); // hotfix: 3px für die focus-outline rechts
	height: $menuButtonHeight;
	border: none;
	background-color: transparent;
	//margin: {
	//	top: $containerMarginVertically;
	//	bottom: $containerMarginVertically;
	//}
	margin-bottom: 4px;
	padding: {
		left: $containerMarginHorizontallyInner + 1px; // containers have a 1px border, so we have to move here accordingly
		right: $containerMarginHorizontallyInner;
		top: 0;
		bottom: 0;
	}
	cursor: pointer;
	text-align: left;
	background-color: transparent;
	flex-shrink: 0;

	&:hover {
		background-color: $linkBgHovered;
	}

	&__img-wrapper {
		display: inline-block;
		position: relative;
		width: $hamburgerWidth;
		height: $hamburgerHeight;
	}

	&__img {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		transition: opacity 400ms ease;
		opacity: 0;
		padding: 0;
		margin: 1.5px 0 0;
		left: 0;
		top: 0;

		&--menu {
			background-image: url("/assets/img/menu.svg?v=2021");
		}

		&--menu-close {
			background-image: url("/assets/img/menu-close.svg?v=2021");
		}

		&--visible {
			opacity: 1;
		}
	}

	&__text {
		font-size: 24px;
		font-weight: $medium-font-weight;
		margin: 0;
		padding: 0;
		color: $logoColor;
		padding-left: 10px;
	}
}
