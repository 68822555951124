// siehe auch _map-overlay-top-right.scss
// und Zeile 372 in dieser Datei (definiert ein 45px x 45px großes Element ohne padding und ohne margin
.ms3-list-toggle-button {
	top: 0;
	bottom: unset;
	right: unset;

	display: block;
	box-sizing: content-box; // oder +2px für Border bei Breite und Höhe dazuzählen
	width: $mapOverlayButtonSize;
	height: $mapOverlayButtonSize;

	border: 0;

	background: white;
	border-radius: $containerBorderRadius;
	pointer-events: auto;
	transition: margin 300ms ease-in-out;
	box-shadow: none;

	&::before {
		display: block;
		width: 100%;
		height: 100%;
		content: '';
		background-repeat: no-repeat;

		background-image: url("../img/mapsight-ui/fullscreen.svg?v=2021");
		// fullscreen.svg is 40x40 aber optisch zu klein zu den anderen
		background-size: 48px 48px;
		margin-left: -2px;
		margin-right: -2px;
	}

	&::after {
		content: none;
		//display: none;
		//content: unset;
		//left: unset;
		//border: none;
	}

	&:hover {
		background: $linkBgHovered;
	}

	&--active {
		&::before {
			background-image: url("../img/mapsight-ui/fullscreen-close.svg?v=2021");
		}

		&::after {
			display: none;
		}
	}

	&:focus {
		background: unset;
		color: unset;
	}

	.vmznds-main-panel-container__content & {
		display: none;
	}
}
