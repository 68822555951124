@import "../variables";

@mixin contenttable() {
	border-style: solid;
	border-width: 0;
	border-spacing: 0;
	border-collapse: collapse;
	background: none;
	border-color: $borderColorA;

	caption {
		background-color: #fff;
		padding: 0.4em 6px 0.4em 6px;
		font-weight: normal;
		font-size: $caption-font-size;
		color: $caption-color;
		text-align: center;
		caption-side: bottom;
	}

	tbody th {
		font-weight: $medium-font-weight;
		vertical-align: middle;
		border: 1px solid $borderColorA;
	}

	tbody tr th {
		border-bottom: 1px solid $borderColorA;

		&:first-child {
			border-left: 0;
			padding-left:  0.5em;
		}

		&:last-child {
			border-right: 0;
			padding-right:  0.5em;
		}
	}

	thead {

		th {
			vertical-align: top;
			padding: 0.3em;
			line-height: 2em;
			font-weight: $medium-font-weight;
			background-color: #fff;
			border: 1px solid $borderColorA;
			border-top: 0;
			border-bottom: 2px solid $borderColorB;

			&:first-child {
				border-left: 0;
				padding-left:  0.5em;
			}

			&:last-child {
				border-right: 0;
				padding-right:  0.5em;
			}
		}
		td {
			border-bottom: 2px solid $borderColorB;
			background-color: #fff;
			border-top: 0;
		}
	}

	tr:nth-child(odd) {
		background-color: $linkBgHovered;
	}

	td {
		border: 1px solid $borderColorA;
		color: $foregroundColorA;
		vertical-align: top;
		padding: 0.3em;

		&:first-child {
			border-left: 0;
		}

		&:last-child {
			border-right: 0;
		}
	}
}
