/* ==========================================================================
   Block: Poi
   Modifier: Webcam
   ========================================================================== */

// alt für webcam-Verwaltung

.vmznds-list--poi .vmznds-poi--webcam {
	.vmznds--list-view & {
		display: inline-block;
		margin: 0 -4px 0 0;
		padding: 5px;
		border: 0;
		vertical-align: top;
		width: 100%;

		.details {
			display: none;
		}
	}

	.vmznds--before-map-view &,
	.vmznds--map-view & {
		width: 100% !important;
	}
}

@media screen and (min-width: $step0MaxWidth) {
	.vmznds-list--poi .vmznds-poi--webcam {
		width: 50% !important;
	}
}

@media screen and (min-width: $step2MaxWidth) {
	.vmznds-list--poi .vmznds-poi--webcam {
		width: 33% !important;
	}
}

@media screen and (min-width: $step3MaxWidth) {
	.vmznds-list--poi .vmznds-poi--webcam {
		width: 25% !important;
	}
}
