.vmznds-navapp-link {
	p {
		padding-left: 40px;
		min-height: 40px;
		background: {
			repeat: no-repeat;
			size: 32px 32px;
			position: 1px 4px;
		}
		font-weight: $medium-font-weight;

		.ms3-pre-routing-box & {
			font-size: 13px;
		}
		.vmznds__infobox--nunav-link & {
			padding-left: 44px;
			min-height: 44px;
			background-position: 0 8px;
		}
	}

	&--bikeCitizens p {
		background-image: url(../img/bikecitizens_logo.png);
	}

	&--nunav p {
		background-image: url(../img/nunav_logo.png?v=2021);
	}
}
