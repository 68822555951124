@import "../variables";

.ms3-pre-routing-box {
	color: $foregroundColorA;
	background-color: $backgroundColorA;
	padding: $containerMarginVerticallyInner $containerMarginHorizontallyInner;

	// TODO: DRY into a mixin
	&__meta {
		margin-left: 10px;

		html.cssgrid & {
			display: grid;
			grid-template-columns: max-content auto;
		}
	}

	&__meta-term {
		html.cssgrid & {
			grid-column-start: 1;
			text-align: right;
			padding-right: 15px;
		}
	}

	&__meta-detail {
		html.cssgrid & {
			grid-column-start: 2;
			margin: 0;
		}
	}

	&__input-container {
		padding: 0;
		border: 0;
		margin: 0;

		.ms3-input-container {
			display: block; // TODO: meh. we use a span inside label so we need to make it a block first, maybe move this to @mapsight/ui?
		}
	}

	&__input-label {
		text-transform: uppercase;
		font-size: 12px; // TODO: hardcoded font size
	}

	&__result {
		border-top: 1px solid $borderColorA;
	}
}
