/* ==========================================================================
   Body tag
   ========================================================================== */

body {
	margin: 0 auto;
	min-width: $bodyMinWidth;
	background-color: white;
	background-repeat: no-repeat;
	background-position: top left;
	background-size: 100%;
	background-attachment: fixed;
}

html,
body,
#out {
	height: 100%;
}
