.vmznds-button-reduced {
	display: block;
	width: 44px;
	height: 44px;
	background-position: 2px 2px;

	background-repeat: no-repeat;
	background-image: url(/assets/img/icons/icon-reduced.svg);

	&--active {
		background-image: url(/assets/img/icons/icon-reduced-active.svg);
	}

	// für den auf der Seite Barrierefreiheit nur als Bild verwendeten Knopf
	.vmznds-content-box__content &.ms3-map-overlay__button {
		display: inline-block;
		margin: 0;
		width: 22px;
		height: 22px;
		min-height: 22px;
		background-position: -4px -4px;
		background-size: 30px 30px;
		&:hover {
			background-color: #fff !important;
		}
	}
}
