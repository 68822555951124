// TODO wo wird das ausgegeben? auf staging konnte ich das nicht reproduzieren, aber dort ist eventuell der typo3-teil veraltet
.vmznds-details {
	.news-img-wrap-outer {
		padding: 0 8px;
	}

	.vmznds-box__inset {
		padding-bottom: 2em;
	}

	.news-img-wrap {
		height: auto;
		overflow: auto;
		white-space: nowrap;
		width: 100%;
		-webkit-overflow-scrolling: touch;

		.outer {
			display: inline-block;
			float: none;
			padding: 0.5em 0;
		}
	}

	footer {
		text-align: right;

		a {
			text-decoration: none;
			text-align: right;

			:hover {
				text-decoration: underline;
			}
		}

		a.vmznds-details__more-link {
			background-color: transparent;
			background-repeat: no-repeat;
			background-position: left center;
			text-decoration: none;
			padding: 0 0 0 1.3em;
			line-height: 1.5em;
		}
	}
}

@include vmznds-step(0) {

}

//.vmznds-details
.news-img-wrap .outer img,
.vmznds-message__imgwrap img {
	border: 1px solid $borderedImageBorderColor;
	box-shadow: 0 0 4px $borderedImageBoxShadowColor;
}
