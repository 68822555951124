/* ==========================================================================
   Misc
   ========================================================================== */

/* Global Box Sizing */
html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*, *:before, *:after {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}

/*
 * Eliminate "Jumps" in Horizontal Centering By Forcing a Scroll Bar
 * @see http://css-tricks.com/eliminate-jumps-in-horizontal-centering-by-forcing-a-scroll-bar/
 */
html {
	overflow-y: scroll;
}

/* Highlighting */
::-moz-selection {
	background: #b3d4fc;
	color: black;
	text-shadow: none;
}

::selection {
	background: #D9E8F4;
	color: black;
	text-shadow: none;
}

* {
	//-webkit-touch-callout:none;
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select:none;
	user-select:none;

	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
}

input, textarea, /*.contenteditable?*/
.ms3-list__main, .ms3-feature-details-content, .ms3-attribution,
.ms3-list__main *, .ms3-feature-details-content *, .ms3-attribution *,
{
	//-webkit-touch-callout:default;
	-webkit-user-select:text;
	-moz-user-select:text;
	-ms-user-select:text;
	user-select:text;

	//-webkit-user-drag: auto;
	//-khtml-user-drag: auto;
	//-moz-user-drag: auto;
	//-o-user-drag: auto;
	//user-drag: auto;
}

* {
	touch-action: manipulation;
}
