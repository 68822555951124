// other links are defined in their enclosing blocks
// see mixins/_links
a {
	&[rel="external"],
	&.external-link-new-window {
		@include vmznds-link-external;

		// repair logo
		&.ms3-logo {
			background: transparent url("../img/mapsight-ui/watermark.svg") no-repeat 0 0;
			background-size: 90px 30px;
		}
	}

	&.vmznds-details__more-link {
		@include vmznds-link;
	}

    &.link {
	    &-internal,
	    &-internal-blank,
	    &-download {
		    @include vmznds-link;
		    background-position: calc(100% + 3px) calc(50% - 1px);
	    }
	    &-external,
	    &-external-blank,
	    &-mail {
		    @include vmznds-link-external;
	    }
    }
}
