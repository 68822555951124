// help out mini-legend
.ms3-attribution {
	// h1 and h2 used for legends only
	h1 {
		font-size: 21px;
	}
	h2 {
		font-size: 16px;
		margin-bottom: 0;
	}

	// turn off legend button in map attribution as we have one in the mini-legend, too
	button {
		display: none;
	}
	li:last-of-type::after {
		content: none;
	}
}
