@import "../variables";

.vmznds-list {
	&__item {
		position: relative;

		h1 {
			line-height: 1.1;
		}

		h2 {
			margin: 0;
			padding: 0;
			font-weight: $medium-font-weight;
			line-height: 1.2;
		}

		p {
			margin: 0 0 2px 0;

			.ms3-tooltip & {
				margin: 0;
			}
		}
	}

	&__more-link--external {
		@include vmznds-link-external;
	}

	&__more-link {
		@include vmznds-link;
		font-size: 12px;
	}

	&__more-link--external,
	&__more-link {
		.ms3-attribution & {
			&:hover {
				font-weight: $medium-font-weight;
			}
		}
		.vmznds-panel-box & {
			font-weight: $medium-font-weight;
		}
	}

	.vmznds-content-box__content &__more-link--external,
	.ms3-modal .ms3-attribution &__more-link--external,
	.vmznds-content-box__content &__more-link,
	.ms3-modal .ms3-attribution &__more-link {
		font-size: 14px;
	}

	.ms3-modal .ms3-attribution &__more-link--external,
	.ms3-modal .ms3-attribution &__more-link {
		&:after {
			content: ' ';
		}
		& > span {
			display: none;
		}
		& + span:before {
			content: '\a';
			white-space: pre;
		}
	}
	//.ms3-modal .ms3-attribution &__more-link,
	//.ms3-modal .ms3-attribution &__more-link--external {
	//	font-weight: $regular-font-weight;
	//	text-decoration: none;
	//	&:hover {
	//		text-decoration: underline;
	//	}
	//}
}
