@mixin vmznds-link-external {
	text-decoration: none;
	cursor: pointer;
	font-weight: $medium-font-weight;

	&:hover,
	&:focus {
		text-decoration: underline;
	}

	.ms3-attribution & {
		padding-right: 8px;
		font-weight: inherit;
	}

    //
	//&:after {
	//	content: ' ';
	//	display: inline-block;
	//	margin-left: 2px;
	//	width: 12px;
	//	height: 12px;
	//	background-image: url("/assets/img/link-external.svg");
	//	background-position: center center;
	//	background-repeat: no-repeat;
	//
	//	.ms3-attribution & {
	//		//content: none;
	//		width: 10px;
	//		height: 10px;
	//		.ms3-modal & {
	//			width: 12px;
	//			height: 12px;
	//		}
	//	}
	//}

	padding-right: 9px;
	background-size: 12px 12px;
	background-position: calc(100% + 3px) 0;
	background-image: url("/assets/img/link-external.svg");
	background-repeat: no-repeat;
}

@mixin vmznds-link {
	@include vmznds-link-external;
	background-image: url("/assets/img/link.svg?v=2021");
	color: $logoColor;
}
