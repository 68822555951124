@import 'breakpoint-sass/stylesheets/breakpoint';

@import '../../variables';

.vmznds-chart {
	g.highcharts-legend-item rect.highcharts-point {
		stroke: rgb(62, 87, 111);
	}

	g.highcharts-legend-item-hidden rect.highcharts-point {
		fill: white;
	}

	&__legend {
		padding: 5px;
	}

	&__legend-groups {
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
	}

	&__legend-group-entry {
		display: flex;
		align-items: center;
		padding: {
			left: 5px;
			right: 5px;
			top: 2px;
			bottom: 2px;
		}
		background-color: #fff;
		color: #000;
		border-radius: 5px;
		border-width: 1px;
		border-style: solid;
		border-color: #000;
		cursor: pointer;
		transition: {
			duration: 200ms;
			property: background-color color;
		}

		&-counts {
			padding-left: 3px;
			font-size: 80%;
		}

		&--has-visible {
			background-color: #75c3ff;
			color: #000;
		}

		&:hover,
		&--active {
			background-color: #0066b4;
			color: #fff;
		}
	}

	&__legend-items {
		margin-top: 10px;
	}

	&__legend-item {
		padding-top: 3px;
		padding-bottom: 3px;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 5px;

		&-indicator {
			display: inline-block;
			width: 25px;
			height: 25px;
			border-radius: 50px;
			border-width: 1px;
			border-style: solid;
			border-color: #000;
			transition: {
				duration: 200ms;
				property: background-color;
			}
		}

		&-name {
		}
	}

	&-wrapper {
		margin: 5px -4px;
		border: {
			style: solid;
			color: #ccc;
			width: 1px;
			radius: 4px;
		}

		background: white;
	}

	&-buttons {
		display: flex;
		padding: 6px;
		margin-bottom: 5px;
		justify-content: space-between;
		border-bottom: 1px solid #ccc;

		background: #f5f5f5;
	}

	&-button {
		&--download {
			display: block;
			padding: 5px 5px 0 5px;
			vertical-align: text-bottom;
		}

		&--submit,
		&--reset {
			height: 27px;
			width: 27px;
			margin: 0 0 0 5px;
			padding: 2px 5px;
			border: 0;
			border-radius: 3px;
			background: #f5f5f5;
		}

		&--reset {
			color: #444;
		}

		&--submit {
			border: 1px solid #ccc;
		}
	}

	&-form {
		display: flex;
		align-items: flex-start;
	}

	&-date-selector {
		width: 170px;
		padding: 2px 5px;
		border: 1px solid #ccc;
		border-radius: 3px;
	}
}
