@import "../variables";

.ms3-feature-details-content {
	color: black;
	font-weight: $regular-font-weight;
	font-family: $fontFamilyLight;
	-webkit-font-smoothing: antialiased;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	font-size: 14px;
	line-height: 19px;

	&--description {
		white-space: normal;

		.ms3-panel--docked-left & {
			padding-top: 6px;
		}
	}
}
