.vmznds-feature-details-schul {
	&--details {
		margin: {
			top: $containerMarginVertically;
			bottom: $containerMarginVertically;
		}
	}

	&__place {
		margin: 0;
		padding: 0;
		font-size: 12px;
		line-height: 16px;
		text-transform: uppercase;
		font-weight: $regular-font-weight;

		margin-top: 0.5 * $containerMarginVertically;
	}

	&__schul {
		margin: 0;
		padding: 0;
		font-size: 14px;
		line-height: 18px;
		font-weight: $medium-font-weight;

		margin-top: 0.5 * $containerMarginVertically;
	}

	&__body {
		margin: 0;
		padding: 0;
		font-size: 14px;
		line-height: 18px;
		margin-top: 0.5 * $containerMarginVertically;
	}

	&__datetime {
		margin: 0;
		padding: 0;
		font-size: 12px;
		line-height: 19px;
		margin-top: 0.5 * $containerMarginVertically;
		font-weight: $regular-font-weight;
		opacity: 0.5;
	}
}
