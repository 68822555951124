.vmznds-legend {
	$autobahnColor: #7797cf;
	$autobahnMiddleColor: #d7d4da;
	$bundesstrColor: #dcbd73;
	$bundesstrBorderColor: #b9af94;
	$kreisstrColor: #e2da88; // Landes- und Kreisstr.
	$kreisstrBorderColor: #bfbc93;

	$cssiconwidth: 22px;
	@mixin vmznds-legend-street-icon {
		position: relative;
		display: inline-block;
		width: $cssiconwidth;
		background-color: #7778;
		margin-bottom: -5px;
		margin-top: 1px;
		height: 19px;

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			width: $cssiconwidth;
		}

		.ms3-attribution & {
			height: 19px;
			margin-bottom: -3px;
		}
	}

	//span + span {
	//	margin-left: $containerMarginHorizontallyInner;
	//}

	h3 {
		font-size: 14px;
		font-weight: $medium-font-weight;
		margin: 0;
	}

	&__icon {
		height: 18px;
		margin-bottom: -3px;
		margin-right: 2px;
		.ms3-attribution & {
			height: unset;
			margin-bottom: -7px;
		}

		// these icons follow the map style
		&--prerouting {
			@include vmznds-legend-street-icon;

			&:before {
				height: 8px;
				bottom: 5px;
				background-color: rgba(4, 116, 150, 0.8);
				border-top: 2px solid rgba(0, 228, 241, 0.8);
				border-bottom: 2px solid rgba(0, 228, 241, 0.8);
			}
		}
		&--los {
			@include vmznds-legend-street-icon;

			&:before {
				height: 7px;
				border-top: 1px solid white;
				border-bottom: 1px solid white;
				bottom: 6px;
			}
			&--free:before {
				background-color: $trafficGreen;
			}
			&--tough:before {
				background-color: $trafficOrange;
			}
			&--jam:before {
				background-color: $trafficRed;
			}
		}

		&--base {
			@include vmznds-legend-street-icon;
			&--a {
				&:before {
					top: 4px;
					height: 12px;
					background-color: $autobahnColor;
				}
				&:after {
					content: '';
					display: block;
					position: absolute;
					left: 0;

					width: $cssiconwidth;
					background-color: $autobahnMiddleColor;
					height: 2px;
					top: 9px;
					// border-top: 2px solid $autobahnMiddleColor;
					// border-bottom: 2px solid $autobahnMiddleColor;
				}
			}

			&--b {
				&:before {
					top: 6px;
					height: 7px;
					background-color: darken($bundesstrColor, 10%);
					border-top: 1px solid darken($bundesstrBorderColor, 10%);
					border-bottom: 1px solid darken($bundesstrBorderColor, 10%);
				}
			}
			&--l {
				&:before {
					top: 7px;
					height: 6px;
					background-color: $kreisstrColor;
					border-top: 1px solid $kreisstrBorderColor;
					border-bottom: 1px solid $kreisstrBorderColor;
				}
			}
		}
	}

	&--anomalies__sign {
		display: inline-block;
		color: $vmzRed;
		font-size: 13px !important;
		font-weight: $bold-font-weight !important;
		letter-spacing: 0.1em;
	}

	// nur für die große Legende setzen
	&__parken {
		line-height: 42px;

		//h3 + span .vmznds-legend__icon,
		//span:first-child .vmznds-legend__icon {
		//	margin-left: -5px;
		//}
	}

	.ms3-attribution & {
		span {
		white-space: nowrap;
		}
		img[src*="-default.svg"] {
			margin-bottom: -11px;
			margin-right: 0px;
			margin-top: -3px;
		}
	}

	.ms3-mini-legend &,
		//&--parking,
		//&--base,
		//&--anomalies,
		//&--los,
		//&--prerouting
	{
		width: 100%;
		display: flex;
		flex-direction: row;
		> * {
			flex-grow: 1;
			flex-shrink: 1;
		}
		img {
			min-width: 15px;
		}
	}
	.ms3-attribution &--parking,
	.ms3-mini-legend &--parking {
		.vmznds-legend__icon {
			height: 26px;
			margin-top: -3px;
			margin-bottom: -8px;
		}
		.vmznds-legend__icon--occupation {
			height: 18px;
			margin: 2px 2px -5px -4px;
			min-width: unset;
		}
	}
	.ms3-attribution &--parking {
		.vmznds-legend__icon,
		.vmznds-legend__icon--occupation {
			height: unset;
		}
	}

	.vmznds-map-overlay-modal--reduced &,
	.vmznds-mini-legend--reduced & {
		.vmznds-legend__icon{
			&--prerouting {
				&:before {
					background-color: black;
					border-color: white;
				}
			}

			&--base {
				background-color: #eee;

				&--a {
					&::after {
						background-color: #4a4a4a;
					}

					&::before {
						background-color: #4a4a4a;
					}
				}

				&--b {
					&::before {
						background-color: #cacaca;
						border-top: 1px solid #fff;
						border-bottom: 1px solid #ffff;
					}
				}

				&--l {
					&::before {
						background-color: #cfcfcf;
						border-top: 1px solid #fff;
						border-bottom: 1px solid #fff;
					}
				}
			}

			&--los {
				&--free {
					display: none;

					& + span {
						display: none;
					}
				}
				&--tough {
					&::before {
						height: 4px;
						bottom: 7px;
						background-color: black;

					}
				}
				&--jam {
					&::before {
						background-color: black;
					}
				}
			}
		}

		.vmznds-legend--anomalies__sign {
			color: #000;
		}
	}
}
