@import "../variables";

.vmznds-state {
	display: inline;
	padding: 0;
	margin: 0;
	white-space: normal;
	font-weight: $medium-font-weight;

	&--0,
	&--1,
	&--2,
	&--3 {
		font-weight: $bold-font-weight;
	}

	header &,
	h1 &,
	h2 &,
	h3 & {
		margin: 0;

		&--1 {
			color: $foregroundColorHeadingState1;
			text-transform: uppercase;
		}

		&--2 {
			color: $foregroundColorHeadingState2;
		}

		&--3 {
			color: $foregroundColorHeadingState3;
		}
	}

}
