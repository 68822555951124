.vmznds-rz-wizard-section {
	margin: 10px;

	fieldset {
		border: 0;
		margin: 0;
		padding: 0;
	}

	input[id*="react-select-"] {
		&.focus-visible {
			outline: none !important;
		}
	}
}
