.vmznds-map-overlay-top-right {
	&__list-toggle-button-wrapper {
		.ms3-map-overlay {
			position: unset;
		}

		.ms3-list-toggle-button {
			position: unset;
			padding: 0;
			margin: 0;
		}
	}
}
