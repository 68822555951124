.ms3-list__icon.vmznds-feature-details--prerouting__icon {
	right: unset;
	left: 7px;
	top: 12px;

	img {
		margin-left: 0;
	}
}
.ms3-list__main.vmznds-feature-details--prerouting__main {
	margin-left: 71px;

	+ p {
		margin-top: 10px;
		margin-bottom: 0;
	}
}
