.tx-felogin-pi1 {

	fieldset {
		border: 0;
		margin: 0;
		padding: 1em 0;
	}

	legend {
		display: none;
	}

	label {
		display: none;
	}

	input {
		color: black;
		outline: 0;
		margin: 0 0 15px;
		box-sizing: border-box;
		padding: .5em 0.5em .5em .5em;
		font-size: 1em;
		line-height: 1.5em;
		font-weight: $regular-font-weight;
		border: 0px solid #fff;
		background: #fff;
		display: block;
		min-height: 2.75em;

		&[type="submit"] {
			padding: .7em 1.5em;
			outline: 0;
			background: #eee;
			font-size: 14px;
			cursor: pointer;
		}
	}
}
