/* ==========================================================================
   Block: Skip links
   ========================================================================== */

.vmznds-skip-link {
	//position: absolute; // fallback
	//position: fixed;
	z-index: 2;
	//top: 0;
	//left: 0;
	//
	//a {
	//	display: block;
	//	padding: 15px 25px;
	//	font-size: 1.3em;
	//	color: $foregroundColorA;
	//	background: $backgroundColorB;
	//}

	// von vmz bremen übernommenn und angepasst
	//position: static;
	background: #000;
	color: #fff;
	display: block;
	margin: 0;
	//min-width: 40%;
	transition: margin .5s ease-out,background .5s linear;
	text-decoration: none;

	// vom vmznds-menu-button text
	font-size: 18px;
	font-weight: $bold-font-weight;

	// Anpassungen
	//position: absolute; // -- geändert für .visuallyhidden.focusable
	min-width: 241px;
	&:focus {
		padding: $containerMarginVertically $containerMarginHorizontally;
	}
}
