// CSS-Verschachtelung vs. Scrollbar T2237
// L. 163 ms3-panel-container-vertical: width: 256
// _panel.scss:10 ms3-panel-docked-left: width 100% → korrekte Breite, außer bei Ubuntu FF
// ms3-feature-selection-info: width: unset → ok
// L. 70 (statt davor) ms3-feature-selection-info__contentent: width: 239px → Fehler


@import "./variables";
@import "./mixins/steps-and-widths";

$ms3-iconPath: '../img/';

$ms3-fullscreen-top: 0px;
$ms3-fullscreen-zIndexWrapper: 10002;

$ms3-option-mapOverlayButtonsShowLabel: false;
$ms3-option-showFeatureCountsInTagSwitcherGroups: moreThanOne;
$ms3-option-showFeatureCountsInTagSwitcherEntries: moreThanOne;

$ms3-verticalPanelWidth: 241px;
$ms3-verticalPanelPadding: 7.5px;

@import "@mapsight/ui/index";

@import "./blocks-ms3/feature-details-content";
@import "./blocks-ms3/features-sorting";
@import "./blocks-ms3/input-container";
@import "./blocks-ms3/list";
@import "./blocks-ms3/list-header";
@import "./blocks-ms3/list-toggle-button";
@import "./blocks-ms3/list-wrapper";
@import "./blocks-ms3/map-overlay";
@import "./blocks-ms3/map-wrapper";
@import "./blocks-ms3/modal";
@import "./blocks-ms3/attribution";
@import "./blocks-ms3/mini-legend";
@import "./blocks-ms3/pre-routing-box";
@import "./blocks-ms3/pre-routing-modality";
@import "./blocks-ms3/pre-routing-note";
// ja, das ist in ./blocks, weil die CSS-Klasse mit vmznds beginnt.
// die anderen pre-routing- sind eigentlich projekt-spezifisch, beginnen aber mit ms3-.
// das gehört eigentlich vereinheitlicht, am besten wen aus der Navigation ein @mapsight/navigation wird zu ms3-navigation
@import "./blocks/feature-details-prerouting";
@import "./blocks-ms3/tag-switcher";
@import "./blocks-ms3/tooltip";
@import "./blocks-ms3/trip-stop-input";
@import "./blocks-ms3/wrapper";
@import "./blocks-ms3/layer-switcher";

.ms3-region-selector {
	display: none;
}

.ms3-panel {
	margin: 0 !important;
}

.ms3-wrapper--mapOnly .ms3-map-row,
.ms3-wrapper--fullscreen .ms3-map-row {
	height: 100% !important;
}

.js .ms3-wrapper--desktop .ms3-feature-selection-info__wrapper,
.js .ms3-wrapper--fullscreen .ms3-feature-selection-info__wrapper {
	width: unset;
}

.ms3-wrapper--desktop .ms3-feature-selection-info__content,
.ms3-wrapper--fullscreen .ms3-feature-selection-info__content,
.ms3-wrapper--mapOnly .ms3-feature-selection-info__content {
	width: 100%;
	padding: 0 $containerMarginHorizontallyInner 20px;
}


//.ms3-wrapper--fullscreen /* .vmznds-panel-box__body */ {
//	scrolbar-width: thin;
//	&::-webkit-scrollbar {
//		width: 11px;
//	}
//}
//.ms3-wrapper--fullscreen .ms3-feature-selection-info__content {
//	width: 239px; // helps against scrollbar taking up space. this implies scrollbar uses the 11px $containerMarginHorizontallyInner padding defined above
//}

.ms3-wrapper--fullscreen .ms3-feature-selection-info {
	margin: 0;
}

.ms3-map-synced-interlay {
	color: #000;
	overflow: hidden;
}

//.ms3-layer-switcher__entries[data-ms3-switcher-entries-group="Andere"],
.ms3-tag-switcher .ms3-switcher-header[data-ms3-switcher-header-group="Bundesländer"] .ms3-switcher-header__count {
	display: none;
}

.ms3-marginal {
	display: none;
}

.ms3-panel {
	display: flex;
	flex-direction: column;

	//.js &--docked-left {
	//       background-color: $backgroundColorA;
	//}
}

.ms3-wrapper--mobile .ms3-main-container {
	margin-left: 0;
	margin-right: 0;

	.ms3-map-wrapper {
		margin-left: 0;
		margin-right: 0;
	}
}

.ms3-panel-wrapper {
	margin-bottom: #{$footerHeight};
}

.ms3-panel-wrapper--empty {
	display: none;
}

.js .ms3-panel--docked-left {
	position: inherit;
}


// FIXME der viewToggle-Button wird der neue Fullscreen-Knopf (ev. mapsight immer in Fullscreen betreiben? und den list-switch-button zu FS-Button umdeklariern.
//  geht trotz mobile:
// weil der FS-Knopf auf mobile ein anderer ist als der FS-Knopf am Desktop und der viewToggle-Knopf nur auf dem Desktop sichtbar ist
//  Bildschirmstates Desktop: FS-mit-Liste, FS-mit-Details, FS-ohne-Liste
//  Bildschirmstates Mobile: MOBILE, MAP_ONLY-mit-Details, MAP-ONLY-ohne-Details
// TODO Mapsight beim breiter werden von MOBILE oder MAP_ONLY nach FS schaltet und nicht nach VIEW_DESKTOP (eventuell parameter defaultDesktopView: VIEW_DESKTOP)
// ViewToggle-Button verschieben mit "top: 66px; left: calc(100vw - 74px);"
.js .ms3-panel--empty {
	//display: none;
	width: 0;
	border: none;
}

.ms3-filter-button {
	&--icon-reset {
		background-image: url("#{$ms3-iconPath}/mapsight-ui/reset-light.svg?v=2021");
	}

	&--icon-sort,
	&--icon-sort-active {
		background-image: url("#{$ms3-iconPath}/mapsight-ui/sort-light.svg?v=2021");
	}
}

.ms3-map-row {
	.ms3-wrapper--desktop &,
	.ms3-wrapper--mobile & {
		border-bottom-style: none;
	}
}

.ms3-feature-selection-info {
	&__header {
		display: none;
	}
}

.ms3-left-marginal {
	.ms3-wrapper--mobile & {
		// FIXME remove this code. it fakes repairs
		display: none;
	}
}

// FIXME make this dependent of logo size and steps
$panelContainerTop: (200px + 2 * ($hamburgerHeight+$hamburgerSpacing));
$panelContainerBottom: $panelContainerTop - $containerMarginVertically - 2px;

.js .ms3-panel-container--vertical,
.ms3-panel-container--vertical {
	height: 100%;
	overflow: hidden;
	padding: {
		left: $containerMarginHorizontally;
		right: 0;
		top: $containerMarginVertically;
		bottom: 0;
	}
	display: flex;
	flex-direction: column;
	width: 256px;
}

.js .ms3-panel-container--horizontal,
.ms3-panel-container--horizontal {
	max-height: 100vh; // unset
	// see blocks/_panel-box.scss
	//.vmznds-panel-box__body {
	//	max-height: 150px;
	//}
}

.ms3-wrapper--mobile .ms3-additional-container {
	padding-top: $containerMarginVertically;
}


.ms3-viewport-button--mobile-view-toggle,
.ms3-map-overlay__button--mobile-view-toggle {
	display: none;
}

.ms3-app-overlay {
	z-index: 20000;
}


.vmznds-panel-option--tag-switcher {
	// tag filter categories
	.ms3-tag-switcher .ms3-switcher-header {
		display: block;
		width: 100%;
		border: {
			style: solid;
			width: 1px;
			color: #D9E8F4;
			radius: $containerBorderRadius;
		}
		margin-top: 1px;
		margin-bottom: 1px;

		&:not(:first-child) {
			margin-top: 9px;
		}

		&--active {
			border-color: $logoColor; //  #0066b4;
			background-color: $logoColor; //#0066b4;
			margin-top: 0;
			margin-bottom: 0;
			border-width: 2px;

			* {
				color: #fff;
			}
		}

		&--active:not(:first-child) {
			margin-top: 8px;
		}

		&--inactive {
			background-color: transparent;

			* {
				color: $logoColor; //#0066b4;
			}
		}

		&__count {
			background-color: transparent;
			border: none;
			font-size: inherit;

			&::before {
				content: "(";
			}

			&::after {
				content: ")";
			}
		}

		.ms3-layer-switcher__button {
			border: none;
			padding: {
				left: 8px;
				right: 8px;
			}
		}
	}

	// tag filter sub-categories
	.ms3-tag-switcher .ms3-layer-switcher__entries {
		margin: 0;
		padding: 0;

		.ms3-layer-switcher__entry {
			padding: 0;
			margin: 8px 8px 0 0;

			.ms3-layer-switcher__button {
				overflow: hidden;
				display: inline-block;
				padding: 0;
				margin: 1px;
				border: {
					style: solid;
					width: 1px;
					color: #D9E8F4;
					radius: $containerBorderRadius !important;
				}
				background-color: #D9E8F4;

				.ms3-layer-switcher__entry__label {
					color: $logoColor; //#0066b4;
					padding: 0 5px;
					margin: 0;
					display: inline-block;
					background-color: #fff;
				}

				.ms3-layer-switcher__entry__count {
					display: inline-block;

					margin: 0;
					border: none;

					border-radius: unset;
					font-size: inherit;

					background-color: transparent;
					color: $logoColor; //#0066b4;
				}

				&--active {
					margin: 0;
					border-width: 2px;
					border-color: $logoColor; //#0066b4;
					background-color: $logoColor; //#0066b4;

					.ms3-layer-switcher__entry__count {
						color: #fff;
					}
				}
			}
		}
	}
}

.ms3-feature-selection-info__close-button {
	display: none;
}

// hide zoom buttons on mobile (we are using "combined-buttons" only for the zoom buttons
// right now, so it should be ok)
.ms3-wrapper--mobile .ms3-map-overlay-combined-buttons {
	display: none;
}

// attribution uses same area as mapsight-logo. this should be fixed in @mapsight/ui
// we set .map-overlay-bottom left accordingly
.ms3-logo {
	width: 90px;
	height: 30px;
	background-size: 90px 30px;
}

// überflüssigen erzwungenen Scrollbar abschalten. Dass CSS reagiert richtig auf einen automatische geschalteten Scrollbar
.ms3-sticky-header__scroll-area {
	overflow-y: auto;
}

.ms3-attribution {
	display: inline-block; // limit white background to text
	background: rgba(255, 255, 255, 0.8);
}

.ms3-wrapper--mapOnly .ms3-feature-selection-info__wrapper,
.js .ms3-wrapper--mapOnly .ms3-feature-selection-info__wrapper {
	height: auto;
}

.ms3-map-overlay__button {
	background-color: $backgroundColorA !important;
}

.ms3-sticky-header__container--stuck {
	background: unset;
}

.ms3-logo {
	opacity: 0.8;
}
