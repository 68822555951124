.vmznds-content-list {
	.ms3-wrapper--fullscreen .vmznds-content-box__content .ce-gallery &__img {
		margin-top: 28px;
		height: 110px;
		width: auto;
		@media screen and (min-width: 1024px){
			margin-top: 19px;
			height: 140px;
		}
	}
}
