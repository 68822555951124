@import "../../../variables";

.vmznds-rz-wizard {
	margin: 5px -4px;
	border: {
		style: solid;
		color: #ccc;
		width: 1px;
		radius: 4px;
	}

	background: white;
	font-size: $base-font-size;

	input[type="text"] {
		font-size: 16px !important;
	}
}
