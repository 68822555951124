@import "../variables";

.ms3-tooltip__inner {
	text-align: left;
	min-width: 300px;
	max-width: 500px;
	white-space: normal;
	padding: $containerMarginHorizontallyInner $containerMarginHorizontallyInner; // beide Male Horizontally für gleichen Abstand
	margin: 0;
}
