/* ==========================================================================
   Steps and Widths
   ========================================================================== */

@mixin vmznds-step($step) {
	@if $step == 0 or $step == "0" {
		@media screen and (max-width: ($step0MaxWidth)) {
			@content;
		}
	}

	@if $step == 1 or $step == "1" {
		@media screen and (min-width: $step0MaxWidth+1) and (max-width: ($step1MaxWidth)) {
			@content;
		}
	}

	@if $step == '1+' {
		@media screen and (min-width: $step0MaxWidth+1) {
			@content;
		}
	}

	@if $step == '1-' {
		@media screen and (max-width: $step1MaxWidth) {
			@content;
		}
	}

	@if $step == 2 or $step == "2" {
		@media screen and (min-width: $step1MaxWidth+1) and (max-width: $step2MaxWidth) {
			@content;
		}
	}

	@if $step == '2+' {
		@media screen and (min-width: $step1MaxWidth+1) {
			@content;
		}
	}

	@if $step == '2-' {
		@media screen and (max-width: $step2MaxWidth) {
			@content;
		}
	}

	@if $step == 3 or $step == "3" {
		@media screen and (min-width: $step2MaxWidth+1) {
			@content;
		}
	}

	@if $step == '3+' {
		@media screen and (min-width: $step2MaxWidth+1) {
			@content;
		}
	}
}
