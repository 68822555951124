// TODO diese CSS-Klassen scheinen nicht mehr benutzt zu werden, außer vielleicht in extern angelieferten geojsons

.vmznds-mapsight .vmznds-poi {
	h1 {
		min-height: 32px;
		font-weight: $medium-font-weight;
		font-size: 21px;
		line-height: normal;
	}

	.vmznds-poi__details__content {
		width: 50%;
		float: left;
		padding-right: 1em;
	}

	.vmznds-poi__details {
		h2 {
			font-weight: $medium-font-weight;
			font-size: 1.2em;
			line-height: 1.4;
			margin-top: 14px;

			&:first-child {
				margin-top: 8px;
			}
		}

		dl {
			padding: 0.5em 0;
		}

		dt, dd {
			float: left;
		}

		dt {
			clear: both;
		}

		dt:after {
			content: ":";
		}

		dd {
			padding: 0 0.5em 0 0.5em;
		}
	}
}

.vmznds--map-view {
	.vmznds-poi__content {
		display: none;
	}

	.vmznds-poi__details {
		.vmznds-poi__details__content--left {
			width: 50%;
			max-width: 320px;
			float: left;
			padding-right: 1em;

		}

		.vmznds-poi__details__content--right {
			width: 50%;
			float: left;
			padding-right: 1em;
		}

		.vmznds-additional-images {
			width: auto;
			float: left;
			padding-right: 0.5em;

			img {
				border: 1px solid black;
				width: 100%;
				max-width: 120px;
				height: auto;
				filter: grayscale(100%);
			}
		}
	}
}

.vmznds-pois-details {

	figure.vmznds-webcam {
		margin: 4px 7px 7px;

		img {
			width: 100%;
			max-width: 650px;
			height: auto;
		}
	}

	figure.vmznds-additional-images {
		display: inline-block;
		vertical-align: top;
		margin: 4px 0 0 7px;
		max-width: 250px;
		width: 100%;

		img {
			width: 100%;
			height: auto;
		}

		figcaption {
			width: 100%;
		}
	}
}

@media screen and (min-width: $step3MaxWidth) {
	.vmznds-poi__details__content--right {
		width: 66% !important;
	}
	.vmznds-poi__details__content--left {
		width: 33% !important;
	}
	.vmznds-additional-images {
		width: auto !important;
	}
}

@import "poi/webcam";

@media screen and (max-width: $step0MaxWidth) {
	.vmznds-pois-details .vmznds-additional-images {
		width: 45% !important;
		min-width: 100px
	}
}

@media screen and (min-width: $step0MaxWidth) {
	.vmznds-pois-details .vmznds-additional-images {
		width: 25% !important;
		min-width: 150px;
		max-width: 190px !important;
	}
}
