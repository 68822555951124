@import "../variables";

// TODO move definition of responsive interactions to _panel-boxes.scss
.vmznds-panel-box {
	$b: &;

	display: flex;
	flex-direction: column;
	padding-bottom: 0.5 * $containerMarginVertically;
	overflow: hidden;

	&--urgent-school,
	&--urgent-urgent,
	&--urgent-info_green,
	&--urgent-info_blue {
		#{$b}__body,
		#{$b}__footer {
			line-height: 17px;
			font-weight: $medium-font-weight;
		}
	}

	&--urgent-school {
		#{$b}__headline-wrapper {
			background-color: $vmzOrange;
			color: white;
		}

		.vmznds-close-button::before, .vmznds-close-button::after {
			background-color: white;
		}
	}

	&--urgent-urgent {
		#{$b}__headline-wrapper {
			background-color: $vmzRed;
			color: white;
		}

		.vmznds-close-button::before, .vmznds-close-button::after {
			background-color: white;
		}
	}

	&--urgent-info_green {
		#{$b}__headline-wrapper {
			background-color: $vmzGreen;
			color: white;
		}

		.vmznds-close-button::before, .vmznds-close-button::after {
			background-color: white;
		}
	}

	&--urgent-info_blue {
		#{$b}__headline-wrapper {
			background-color: $logoColor;
			color: white;
		}

		.vmznds-close-button::before, .vmznds-close-button::after {
			background-color: white;
		}
	}


	&--auto-close {
	}

	&--body-no-scroll {
	}

	&--main {
	}

	//// TODO nötig?
	//&__link {
	//	display: block;
	//	text-decoration: none;
	//}

	&__headline-wrapper {
		flex-shrink: 0;
		display: flex;
		flex-direction: row;
		margin: 0;
		min-height: 38px;


		&.focus-visible:focus {
			outline: none !important;

			a:after {
				@include panel-box-heading-arrow-arrow;
				outline: $focus-outline;
			}

			~ .vmznds-panel-box__footer a, {
				outline: $focus-outline;
			}
		}
	}

	&__headline {
		$normal-font-size: 14px;
		$main-font-size: 18px;

		flex-grow: 1;
		flex-shrink: 1;
		font-weight: $medium-font-weight;
		font-size: $normal-font-size;
		line-height: 1;
		text-transform: uppercase;
		margin: 0;
		padding: 0;

		&-inner {
			position: relative;
			display: block;
			text-decoration: none;
			height: 100%;
			line-height: 1.4em;

			margin: 0;
			padding: {
				left: $containerMarginHorizontallyInner;
				right: $containerMarginHorizontallyInner;
				top: 38px - 4px - $normal-font-size;
			}

			#{$b}--main & {
				padding: {
					top: 38px - 4px - $main-font-size;
				}
				font-size: $main-font-size;
			}

			.js .ms3-panel-container--horizontal &,
			.ms3-panel-container--horizontal & {
				padding: {
					top: 32px - 4px - $normal-font-size;
				}
			}

			.js .ms3-panel-container--horizontal #{$b}--main &,
			.ms3-panel-container--horizontal #{$b}--main & {
				padding: {
					top: 32px - 4px - $main-font-size;
				}
			}
		}
	}

	&__headline-close-button {
		flex-shrink: 0;
		flex-grow: 0;
		padding-top: $containerMarginVertically;
	}

	&__header {
	}

	&__body {
		flex-shrink: 1;
		flex-grow: 1;
		overflow: auto;

		.ms3-panel-wrapper--fullscreen #{$b}--auto-close & {
			// TODO: adjust
			@media screen and (max-height: 1000px) {
				display: none;
			}
		}

		#{$b}--body-no-scroll & {
			overflow: hidden;
		}

		.js .ms3-panel-container--horizontal &,
		.ms3-panel-container--horizontal & {
			max-height: 200px; // Chris: 160px
		}

		.js .ms3-panel-container--horizontal #{$b}--details-bigmobile &,
		.ms3-panel-container--horizontal #{$b}--details-bigmobile & {
			max-height: 310px;
			@media screen and (max-height: 670px) {
				max-height: 280px;
			}
			@media screen and (max-height: 630px) {
				max-height: 240px;
			}
			@media screen and (max-height: 590px) {
				max-height: 200px;
			}
		}

		.js .ms3-panel--docked-below,
		.ms3-panel--docked-below {
			max-height: unset;
		}
	}

	@at-root .ms3-panel--docked-below .ms3-feature-details-content__summary,
	&__footer {
		flex-shrink: 0;
		text-align: right;
		font-size: 12px;
		min-height: 29px;
		padding: {
			left: $containerMarginHorizontallyInner;
			right: $containerMarginHorizontallyInner;
			top: $containerMarginVerticallyInner;
		}

		a {
			padding: 1px;
			text-decoration: none;
			color: $logoColor;
			&:hover {
				//background-color: $linkBgHovered;
				text-decoration: underline;
			}

			&.vmznds-list__more-link {
				padding-right: 12px;
				background-position: 100% 0;
			}
		}

		.js .ms3-panel-container--horizontal &,
		.ms3-panel-container--horizontal & {
			min-height: 22px;
		}

		&:empty {
			display: none;
		}
	}
	&--key-urgent &__footer {
		font-size: 14px;
	}

	&__info {
		padding: $containerMarginVerticallyInner $containerMarginHorizontallyInner;
	}

	// all sections
	&__headline,
	&__header,
	&__body,
	&__footer {
		&:not(:first-child) {
			border-top: {
				style: solid;
				width: 1px;
				color: $containerBorderColor;
			}
		}
	}

	// this has been main-panel-container__box but in fact it's only used a wrapper around .vmznds-panel-box
	&__box {
		display: flex;
		align-items: stretch;
		flex-direction: column;
		overflow: hidden;

		&--with-border {
			border-width: 1px;
			border-radius: $containerBorderRadius;
			border-color: $containerBorderColor;
			border-style: solid;

			.ms3-wrapper--mapOnly & {
				border-radius: 0;
				border-right: none;
				border-left: none;
				border-bottom: none;
			}
		}

		& + & {
			margin-top: $containerMarginHorizontallyInner;
		}

		.ms3-wrapper--mapOnly & {
			width: 100%;
		}

		// only "trigger" box if it's a direct child of content, make it "neutral" otherwise
		.ms3-wrapper--fullscreen .vmznds-panel-boxes > & {
			&--height-limit {
				max-height: 36vh;
				flex-shrink: 0;
			}

			&--height-expand {
				flex-shrink: 1;
				@media screen and (max-height: 799px) {
					flex-shrink: 0;
				}
			}

			&--height-keep {
				flex-shrink: 0;
				flex-grow: 0;
			}
		}
	}
}
