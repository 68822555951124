/* ==========================================================================
   Variables
========================================================================== */

$bodyMinWidth: 280px;
$bodyMaxWidth: 1400px;

$step0MaxWidth: 480px;
$step1MaxWidth: 900px;
$step2MaxWidth: 1024px;
$step3MaxWidth: 1400px;

// this has to be kept in sync with @mapsight
$mobileMaxWidth: 767px;

$boxHorizontalGap: 22px;

$leftExpandedWidth: 180px;
$leftDecoyWidth: 50px;
$containerBorderRadius: 5px;

$containerMarginVertically: 15px; // 8
$containerMarginHorizontally: 15px; // 7
$containerMarginVerticallyInner: 8px; // 8
$containerMarginHorizontallyInner: 11px; // 7

$mapOverlayButtonSize: 44px; //  + ggf. 2x 1px Border

$hamburgerWidth: 17.5px;
$hamburgerHeight: 17.5px;
$hamburgerSpacing: 5px;

$menuButtonHeight: 50px;

// entry height + margin
$footerHeight: 22px;

$listItemPadding: $containerMarginHorizontallyInner;

//$fontFamilyLight: "WorkSans", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, "Helvetica Now", "HelveticaNow", FreeSans, "Nimbus Sans", "Nimbus Sans L", "Liberation Sans", Arial, "Lucida Grande", sans-serif;
$fontFamilyLight: "HKGrotesk", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, "Helvetica Now", "HelveticaNow", FreeSans, "Nimbus Sans", "Nimbus Sans L", "Liberation Sans", Arial, "Lucida Grande", sans-serif;

// The base font size.
$base-font-size: 14px;

// The base line height determines the basic unit of vertical rhythm.
$base-line-height: 19px;

// The length unit in which to output vertical rhythm values.
// Supported values: px, em, rem.
$rhythm-unit: 'px';

// Set this to true to force CSS output to exactly match normalize.css.
$strict-normalize: true;

// The font sizes for h1-h6.
$h1-font-size: 28px;
$h2-font-size: 21px;
$h3-font-size: 16px;
$h4-font-size: 14px;
$h5-font-size: 12px;
$h6-font-size: 9px;

$bold-font-weight: 800;
$medium-font-weight: 600;
$regular-font-weight: 400;

$boxHeadFontWeight: $regular-font-weight;
$boxHeadFontSize: 21px;


$border-color: #6F8698;

$iconsPathPrefix: '../img/icons/';

$logoColor: #0066b4;
$vmzRed: #BE1E1E;
$vmzGreen: #008F48; // #3aa333; // TODO $vmzGreen
$vmzOrange: #C67B00;
$preselectedBgColor: #deecf6;
$linkBgHovered: #f2f2f2;

$foregroundColorA: black;
$foregroundColorA--sub: $logoColor; // #005799;
$backgroundColorA: white;
$borderColorA: #707070; // #7777; entspricht C0C0C0 auf weißem Grund // #7f7f7f; // #999999;
$borderColorB: #0a001f;

$foregroundColorB: black;
$backgroundColorB: #e0e0e0;

$foregroundColorC: black;
$foregroundColorC--sub: #77aed9;
$backgroundColorC: white;
$backgroundColorC--hover: $linkBgHovered; // #00386d;

$foregroundColorState1: white;
$backgroundColorState1: $vmzRed; // #e40413;
$foregroundColorHeadingState1: $backgroundColorState1;
$backgroundColorHeadingState1: transparent;

$foregroundColorState2: white;
$backgroundColorState2: $vmzOrange; // #ed6446;
$foregroundColorHeadingState2: $backgroundColorState2;
$backgroundColorHeadingState2: transparent;

$foregroundColorState3: white;
$backgroundColorState3: $vmzGreen; // #3aaa35;
$foregroundColorHeadingState3: $backgroundColorState3;
$backgroundColorHeadingState3: transparent;

$containerBoxShadowColor: rgba(0, 0, 0, 0.27);
$leftBoxShadowColor: rgba(40, 40, 90, 0.5);

// specific use cases
$mapControlForegroundColorA: black;
$mapControlForegroundColorB: $borderColorA; // ehedem #888;
$containerBorderColor: $borderColorA; // ehedem #7777;
$mainNavigationBorderColor: $borderColorA;
$boxHeadBorderColor: $borderColorA;
$boxHeadForegroundGlowColor: #00386d;
$boxInsetBackgroundColor: $backgroundColorC;
$listItemBorderColor: $backgroundColorA;
$footerForegroundColor: $backgroundColorA;
$borderedImageBorderColor: #6F8698;
$borderedImageBoxShadowColor: rgba(0, 0, 0, 0.22);

// content

$imageCaptionColor: $mapControlForegroundColorB;


$caption-font-size: 14px;
$caption-color: $borderColorA;

//$ms3-borderColor: $border-color;
$ms3-borderColor: $containerBorderColor;
$ms3-themeColorA: $logoColor;
$ms3-secondaryColor: $borderColorA; // always additonally change the color in src/package-override/@mapsight/ui/img/mapsight-ui/check-grey*.svg


$vmzMapButtonMargin: 8px;

 //$focus-outline: 3px dashed $logoColor; // sehr sichtbar. das ist wichtig für die Leute die es brauchen
$focus-outline: 3px dotted black !important; // sehr auffälig


// colors for traffic layer
$trafficGreen: rgba(0, 255, 12, 0.73);
$trafficOrange: rgba(255, 132, 0, 0.74);
$trafficRed: rgba(255, 31, 0, 0.73);
