.ms3-pre-routing-modality {
	position: absolute;
	top: 43px;
	z-index: 11;

	// white background to hide elements scrolling below
	left: 1px;
	width: calc(100% - 2px);
	background: white;
	border-top: 6px solid white; // simply to get 6px extra height above content

	border-bottom: 1px solid $borderColorA;

	&__button {
		display: inline-block;
		width: 32%; // 32% sieht natürlicher aus als die korrekten 33%

		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
		}

		// with slow internet connections, the blue image stays for a long time, till the black one get's loaded. this ensures a visual effect on supporting browsers
		&[aria-checked="true"] {
			filter: grayscale(100%);
		}
	}
}
