@import "../variables";

.ms3-map-overlay {
	color: #000;
	overflow: visible;

	z-index: 5000;

	&::before {
		width: 100%;
		height: 100%;
		background-size: #{$mapOverlayButtonSize + 2px} #{$mapOverlayButtonSize + 2px}; // Border der im & liegenden __button berücksichtigen
	}

	&__button {
		box-sizing: content-box; // oder +2px für Border bei Breite und Höhe dazuzählen
		width: $mapOverlayButtonSize;
		height: $mapOverlayButtonSize;

		margin: $vmzMapButtonMargin;
		border: {
			style: solid;
			width: 1px;
			color: $containerBorderColor;
			radius: $containerBorderRadius;
		}

		& + &,
		.ms3-layer-switcher-overlay & {
			margin-top: 0;
		}

		&--desktop-view-toggle--fullscreen,
		&--desktop-view-toggle--desktop {
			display: none;
		}

		&--zoom {
			width: 43px; // + und - 1px nach link verschieben, kleine Korrektur
		}

		&--info::before {
			background-size: #{1.5 * 9px} #{1.5 * 18px};
		}

		&:hover {
			background-color: $linkBgHovered !important;
		}
	}

	.ms3-wrapper--fullscreen & {
		overflow: visible;
	}

	&-combined-buttons {
		width: #{$mapOverlayButtonSize + 2px}; // border der innenliegenden __button berücksichtigen
		overflow: visible;
		margin: $vmzMapButtonMargin;
		border: none;
		background-color: transparent !important;

		& + &,
		.ms3-layer-switcher-overlay & {
			margin-top: 0;
		}

		.ms3-map-overlay__button {
			box-sizing: content-box; // oder +2px für Border bei Breite und Höhe dazuzählen (auch bei & + &)
			width: $mapOverlayButtonSize;
			height: $mapOverlayButtonSize;

			border: {
				style: solid;
				width: 1px;
				color: $containerBorderColor;
				radius: $containerBorderRadius;
			}
		}
		&__button {
			&:not(:last-child) > .ms3-map-overlay__button {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border-bottom: none;
			}
			& + & > .ms3-map-overlay__button {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
	}

	// those buttons in the bottom right corne have a aria-label set. there's no need to repeat it in the button label.
	// TODO remind that the other buttons have no aria-label. that should be unified
	&__area--bottom-right .ms3-map-overlay__button__label {
		display: none;
	}
}
