@mixin vmznds-list-group-name {
	$fontSize: 18px;

	max-width: 100%;
	margin: 0;
	padding: {
		top: 38px - $fontSize - 4px;
		right: $containerMarginHorizontallyInner;
		left: $containerMarginHorizontallyInner;
		bottom: 4px;
	};
	border-bottom: 1px solid $containerBorderColor;

	font-weight: $regular-font-weight;
	font-size: $fontSize;
	line-height: 1;
}
