@import "../variables";

.vmznds-situation--tic {
	&__road-number {
		display: inline-block;
		margin: 0;
		padding: 12px 0 0;
		width: calc(100% - 53px);
		min-height: 32px;

		font-weight: $medium-font-weight;
		line-height: 20px !important; // important because it collides with `.vmznds-list__item h1`
		font-size: 25px;
		hyphens: auto;
		hyphenate-limit-chars: 10 4 4;

		color: #000;

		.ms3-tooltip & {
			padding: 0;
		}
	}

	&__shortDesc {
		display: inline;
		margin: 0;
		padding: 0;
		font-weight: $medium-font-weight;
		font-size: 14px;
	}

	&__subheading {
		margin: 0;

		font-weight: $regular-font-weight;
		font-size: 14px;
		line-height: 22px;
	}

	&__datetime {
		display: inline-block;
		margin: 0;
		color: #000a;
	}

	&__direction {
		// FIXME: max-width: calc(100% - 64px); macht die Startseite kaputt, benötigt absolute, feste Zahl
		margin: 0;

		font-weight: $medium-font-weight;
		font-size: 14px;
		line-height: 20px !important; // important because it collides with `.vmznds-list__item h2`
	}
}
