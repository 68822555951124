.ms3-list-wrapper {
	padding: 0;
	border: 0;

	.js .ms3-panel--docked-left & {
		margin: 0;
		height: unset;
		width: unset;
		overflow-scrolling: unset;
		-webkit-overflow-scrolling: unset;
		overflow: unset;
	}
}
