@import "../variables";

.ms3-map-wrapper {
	transition: max-height 20ms ease-out; // basically disable transition to make transition from/to fullscreen less flickery, but keeping transitionend events

	@media (min-aspect-ratio: 16/9 /* 2/1 */), (max-height: 479px) {
		max-height: 75vh; // alten Wert überschreiben
		min-height: 205px;
		height: 50vh;

		// keep those
		.ms3-wrapper--mapOnly &,
		.ms3-wrapper--fullscreen & {
			height: auto;
			max-height: calc(100vh - #{$ms3-fullscreen-top});
		}
	}
	// we do not have --desktop
	//.ms3-wrapper--desktop & {
	//	min-height: calc(100vh - 216px);
	//}
	//
	//.vmznds-template--full .ms3-wrapper--desktop &,
	//.vmznds-template--left .ms3-wrapper--desktop & {
	//	min-height: calc(100vh - 187px);
	//}

	border-width: 1px;
	border-radius: $containerBorderRadius;
	border-color: $containerBorderColor;
	border-style: solid;
	overflow: hidden;
	margin: {
		left: $containerMarginHorizontally;
		right: $containerMarginHorizontally;
		top: $containerMarginVertically;
		bottom: #{$footerHeight};
	}
	left: 0;
	background: $backgroundColorA;
	transition: width 400ms ease-out;

	// &::after,
	&::before {
		content: "";

		position: absolute;
		left: 0;
		right: 0;
		z-index: 15000;

		display: block;
		height: 30px;

		user-select: none;
		pointer-events: none;
	}

	&::before {
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		box-shadow: #444b inset 0 0 3px 0;
	}

	//&::after {
	//	bottom: 0;
	//	box-shadow: rgba(0, 0, 0, .7) inset 0 -40px 30px -40px;
	//}


	.ms3-panel-wrapper--empty + &,
	.ms3-wrapper--mapOnly & {
		margin: 0;
		border: none;
		border-radius: 0;
		&::before {
			content: none;
		}
	}
}
